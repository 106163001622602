import { Box, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'

import { UserRoles } from '../../../constants/profile'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useWindowSize } from '../../../hooks/useWindowSize'
import {
  AccountMenuActions,
  MobileMenuAccountActions,
} from '../../../types/Auth'
import { getUserAccountOptions } from '../../../utils/helpers/auth'
import {
  adminDashboardApiPath,
  dashboardPath,
  dashboardProfilePath,
  eventsListPath,
  marketplacePath,
  onboardingRolePath,
  profilePath,
  vendorsPath,
  venuesPath,
} from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import AuthMenu from '../../Auth/AuthMenu'
import BurgerMobile from '../../svg/BurgerMobile'
import UserIcon from '../../svg/UserIcon'
import { Loader } from '../../ui/Loader'
import { SimpleModal } from '../../ui/Modals/SimpleModal'
import { PopperMenu } from '../../ui/PopperMenu'
import { Notifications } from '../Notifications'

import { AdminUsersList } from './AdminUsersList'

export const AccountMenu: React.FC<{
  setRenderSearch?: (renderSearch: boolean) => void
}> = ({ setRenderSearch }) => {
  const history = useHistory()
  const { isOpen, toggleModal } = useSimpleModal()
  const { isAuthorized, authUser, logout, isLoading } = useAuthData()
  const { clearEventsFromStorage } = useStorageEventsData()
  const { isMobile } = useWindowSize()
  const { isAbs, isPartner } = usePartner()

  const accountOptions = getUserAccountOptions(
    authUser,
    isMobile,
    !!isPartner,
    !!isAbs
  )

  const userNameArray = authUser?.name?.split(' ') || []
  const userInitials =
    authUser?.name && userNameArray.length > 1
      ? `${userNameArray[0][0]}${userNameArray[1][0]}`
      : authUser?.name
      ? authUser?.name[0]?.toUpperCase()
      : null

  const handleAccountMenuSelect = async (action: string) => {
    switch (action) {
      case AccountMenuActions.Profile:
        const path = UserRoles.isClient(authUser?.role)
          ? profilePath
          : dashboardProfilePath
        history.push(path)
        break
      case AccountMenuActions.Dashboard:
        history.push(dashboardPath)
        break
      case AccountMenuActions.AdminDashboard:
        window.open(adminDashboardApiPath, '_self')
        break
      case AccountMenuActions.UserLogin:
        toggleModal()
        break
      case MobileMenuAccountActions.Vendors:
        history.push(vendorsPath)
        break
      case MobileMenuAccountActions.Venues:
        history.push(marketplacePath)
        break
      case MobileMenuAccountActions.MyEvents:
      case MobileMenuAccountActions.CreateNewEvent:
        history.push(eventsListPath)
        break
      case AccountMenuActions.Logout:
        const successLogout = await logout()
        if (successLogout) {
          clearEventsFromStorage()
          history.push(venuesPath)
        }
        break
      default:
        history.push(onboardingRolePath)
        break
    }
  }

  return isAuthorized ? (
    <Box display="flex" alignItems="center">
      {!isMobile ? (
        <>
          {isAbs && <Notifications />}
          <PopperMenu
            withSearch={true}
            setRenderSearch={setRenderSearch}
            darkMode
            withPointer={false}
            placement="bottom-end"
            options={accountOptions}
            onSelectAction={handleAccountMenuSelect}
          >
            {isAbs && userInitials ? (
              <IconButton
                sx={{
                  background: (theme) => theme.palette.common.headerIconBg,
                  '&:hover': {
                    background: (theme) => theme.palette.common.headerIconHover,
                  },
                  width: '44px',
                  height: '44px',
                  ml: '16px',
                }}
              >
                <Typography
                  variant="body1"
                  fontFamily="Acumin Pro"
                  sx={{
                    textTransform: 'uppercase',
                    color: '#20202C',
                    fontSize: '19px',
                    lineHeight: '19px',
                  }}
                >
                  {userInitials}
                </Typography>
              </IconButton>
            ) : (
              <Box display="flex" ml={1}>
                <IconButton
                  sx={{
                    background: (theme) => theme.palette.common.headerIconBg,
                    '&:hover': {
                      background: (theme) =>
                        isAbs
                          ? theme.palette.common.headerIconHover
                          : theme.palette.action.hover,
                    },
                  }}
                >
                  <UserIcon />
                </IconButton>
              </Box>
            )}
          </PopperMenu>
        </>
      ) : (
        <>
          <Notifications />
          <PopperMenu
            withSearch={true}
            darkMode
            setRenderSearch={setRenderSearch}
            withPointer={false}
            placement="bottom-end"
            options={
              isAbs
                ? accountOptions.filter(
                    (item) => item.value !== MobileMenuAccountActions.Venues
                  )
                : accountOptions
            }
            onSelectAction={handleAccountMenuSelect}
          >
            <Box display="flex" ml={1}>
              <BurgerMobile />
            </Box>
          </PopperMenu>
        </>
      )}
      {isLoading && <Loader size={20} position="absolute" />}
      <SimpleModal
        style={{ overflow: 'inherit' }}
        open={isOpen}
        onClose={toggleModal}
      >
        <AdminUsersList onSuccess={toggleModal} />
      </SimpleModal>
      {!isMobile && !isAbs && <Notifications />}
    </Box>
  ) : (
    <AuthMenu setRenderSearch={setRenderSearch} />
  )
}
