import Bugsnag from '@bugsnag/js'
import { styled } from '@material-ui/core'
import React, { Component, ErrorInfo, ReactNode, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import BlaceLogo from '../../assets/images/blace.png'
import Error from '../../assets/images/error.png'
import { generateHash } from '../helpers/common'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  lastErrorHash: string
}

const StyledPage = styled('div')`
  .full-screen {
    background-color: #20202c;
    width: 100%;
    height: 100%;
    color: white;
    font-family: 'Arial Black';
    text-align: center;
  }

  .container {
    width: 50%;
    height: calc(100vh - 70px);
    display: block;
    margin: 0 auto;
    font-family: Poppins;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error-text {
    font-size: 24px;
    color: #e04e2f;
    margin: 40px 0;
  }

  .wrong-text-title {
    margin-top: 40px;
  }

  .wrong-text {
    font-size: 39px;
  }

  a {
    display: inline-block;
    margin-top: 40px;
    cursor: pointer;
    color: white;
  }

  .to-home {
    text-decoration: none;
    padding: 8px 20px;
    border: 1.5px solid #fff;
    &:hover {
      color: lightgray;
    }
  }

  p {
    margin: 0;
  }

  .logo {
    width: 122px;
  }
`

const ErrorBoundaryContext = React.createContext(() => {})

export const useErrorHandling = () => {
  return React.useContext(ErrorBoundaryContext)
}

export const ErrorPage: React.FC<{ hash?: string; resetError: () => void }> = ({
  hash,
  resetError,
}) => {
  const { t } = useTranslation()

  return (
    <StyledPage>
      <div className="full-screen">
        <a href="https://www.blace.com/">
          <img src={BlaceLogo} className="logo" />
        </a>
        <div className="container">
          <div>
            <img src={Error} />
            {hash && (
              <Trans i18nKey="common.error.hash">
                <p className="error-text">Error: {{ hash }}</p>
              </Trans>
            )}
            <p className="wrong-text wrong-text-title">
              {t('common.error.title', 'Something went wrong.')}
            </p>
            <p className="wrong-text">
              {t(
                'common.error.subtitle',
                'We are looking to see what happened'
              )}
            </p>
            <a className="to-home" onClick={resetError}>
              {t('common.error.toHome', 'Go to home page')}
            </a>
          </div>
        </div>
      </div>
    </StyledPage>
  )
}

class CustomErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    lastErrorHash: '',
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, lastErrorHash: `${generateHash(error.message)}` }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error:', error)
    console.error('Error Info:', errorInfo)
    Bugsnag.notify(error, (event) => {
      event.addMetadata('data', {
        hash: generateHash(error.message),
        errorInfo,
      })
    })
  }

  triggerError = () => {
    this.setState({ hasError: true, lastErrorHash: '' })
  }

  resetError = () => this.setState({ hasError: false })

  public render() {
    return (
      <ErrorBoundaryContext.Provider value={this.triggerError}>
        {this.state.hasError ? (
          <ErrorPage
            hash={this.state.lastErrorHash}
            resetError={this.resetError}
          />
        ) : (
          this.props.children
        )}
      </ErrorBoundaryContext.Provider>
    )
  }
}

export default CustomErrorBoundary
