import { Box, Typography } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Room } from '../../../../../types/Venue'
import { formatToCurrency } from '../../../../../utils/helpers/price'

export const RoomPricing: React.FC<{ room?: Room }> = ({ room }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        className={room?.details ? 'section-block' : ''}
      >
        <Typography variant="h2">
          {t('business.pricing.landlord.heading', 'Pricing')}
        </Typography>
        {(room?.display_pricing ||
          (!room?.display_pricing && !room?.pricing_details)) && (
          <Box>
            {room?.pricing_details && (
              <Typography
                variant="h3"
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="normal"
              >
                {t('business.pricing.landlord.staring', 'Minimum Rate')}
              </Typography>
            )}
            <Typography variant="body1" style={{ fontWeight: 600 }} mt="1em">
              {room?.display_pricing
                ? `${formatToCurrency(room?.price, false)} / ${
                    room?.short_pricing_details_label
                  }`
                : room?.pricing_details
                ? ''
                : t(
                    'business.pricing.landlord.notDisplay',
                    'Inquire for Pricing'
                  )}
            </Typography>
          </Box>
        )}
        {room?.pricing_details && (
          <Box mt={room?.display_pricing ? 5 : 0}>
            {room?.display_pricing && (
              <Typography
                variant="h3"
                fontFamily="Poppins"
                fontSize={16}
                fontWeight="normal"
              >
                {t('business.pricing.landlord.details', 'Pricing Details')}
              </Typography>
            )}
            <Typography
              dangerouslySetInnerHTML={{ __html: room?.pricing_details }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
