const CreateNewEventIcon = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5Zm4.5 11.063a.188.188 0 0 1-.188.187H12.75v3.563a.188.188 0 0 1-.188.187h-1.124a.188.188 0 0 1-.188-.188V12.75H7.687a.188.188 0 0 1-.187-.188v-1.124c0-.104.084-.188.188-.188h3.562V7.687c0-.103.084-.187.188-.187h1.124c.104 0 .188.084.188.188v3.562h3.563c.103 0 .187.084.187.188v1.124Z"
        fill="#597EF7"
      />
    </svg>
  )
}

export default CreateNewEventIcon
