import { Box, IconButton } from '@material-ui/core'
import { NavExternalLink } from 'components/NavLink'
import LogoWhite from 'components/svg/LogoWhite'
import { usePartner } from 'hooks/usePartner'
import { useWindowSize } from 'hooks/useWindowSize'
import { homeUrl } from 'utils/paths'

import ABSLogo from '../../assets/images/abs-logo.png'
import BlaceLogo from '../../assets/images/blace.png'

import { GlobalSearch } from './GlobalSearch'

export const Logo = () => {
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()

  if (isMobile) {
    return (
      <NavExternalLink href={homeUrl} style={{ display: 'flex' }}>
        {isAbs ? (
          <img src={ABSLogo} height={48} alt="ABS logo" />
        ) : (
          <IconButton>
            <LogoWhite />
          </IconButton>
        )}
      </NavExternalLink>
    )
  }

  return (
    <Box display="flex" alignItems="center" style={{ position: 'relative' }}>
      <NavExternalLink href={homeUrl} className="logo-wrapper">
        <img src={BlaceLogo} width={122} alt="Blace logo" />
      </NavExternalLink>
      <Box ml={5} display="flex" alignItems="center">
        <GlobalSearch />
      </Box>
    </Box>
  )
}
