import { Box, BoxProps, styled, Typography } from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import FloorNumber from '../../../../components/svg/FloorNumber'
import Guests from '../../../../components/svg/Guests'
import TotalSquare from '../../../../components/svg/TotalSquare'
import SwipeSlider from '../../../../components/ui/SwipeSlider/SwipeSlider'
import { TooltipComponent } from '../../../../components/ui/Tooltip'
import { ImageType } from '../../../../types/general'
import { Room } from '../../../../types/Venue'
import { venueRoomsDetailsPath } from '../../../../utils/paths'
import { StyledItemBottomBar } from '../VenueListItem/VenueListItem.styles'

interface RoomsSliderProps extends BoxProps {
  data: Room[]
  sliderTitle: string
  slidesPerView?: number
  slidesPerGroup?: number
  withoutEndFrame?: boolean
  navigationPosition?: 'bottom' | 'top' | undefined
  tooltipBox?: JSX.Element
  slideWidth?: number
  slideImageHeight?: number
}

export const StyledSubTitle = styled(Typography)`
  margin-left: 10px;
  color: #9396a3;
`

export const RoomPropsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const RoomsSlider: React.FC<RoomsSliderProps> = (props) => {
  const {
    navigationPosition,
    sliderTitle,
    slidesPerView,
    slidesPerGroup = slidesPerView,
    withoutEndFrame,
    data,
    slideWidth,
    slideImageHeight,
    ...boxProps
  } = props

  const history = useHistory()

  const roomImage = React.useCallback((room) => {
    const sorted = room.images?.sort((a: any, b: any) => a.order - b.order)
    return sorted?.[0]
  }, [])

  const goToDetails = (room: Room) => {
    history.push(
      generatePath(venueRoomsDetailsPath, {
        id: room.venue_id,
        roomId: room.id,
      })
    )
  }

  const getListItemTitle = (room: Room) => {
    return (
      <Box display="flex" justifyContent="space-between" width={1}>
        <TooltipComponent placement="top-start" title={room.name}>
          <Typography
            variant="h4"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {room.name}
          </Typography>
        </TooltipComponent>
      </Box>
    )
  }
  const getListItemFooter = (room: Room) => {
    return (
      <RoomPropsContainer>
        <Box display="flex" mb={2} mt={2}>
          <FloorNumber />
          <StyledSubTitle variant="body2">{room.floors} floors</StyledSubTitle>
        </Box>
        <Box display="flex" mb={2} mt={2}>
          <TotalSquare />
          <StyledSubTitle variant="body2">{room.space} SqFt</StyledSubTitle>
        </Box>
        <Box display="flex" mt={2}>
          <Guests />
          <StyledSubTitle variant="body2">
            {room.capacity} guest max
          </StyledSubTitle>
        </Box>
      </RoomPropsContainer>
    )
  }

  const itemList = data.map((room: Room) => ({
    ...room,
    image: roomImage(room),
    imageStaticType: 'venue' as ImageType['static'],
    imageSize: 'medium' as ImageType['size'],
    onClick: () => goToDetails(room),
    footer: (
      <StyledItemBottomBar
        title={getListItemTitle(room)}
        subtitle={getListItemFooter(room)}
        position="below"
      />
    ),
  }))

  return (
    <SwipeSlider
      navigationPosition={navigationPosition}
      withoutEndFrame={withoutEndFrame}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      data={itemList}
      title={sliderTitle}
      slideWidth={slideWidth}
      slideImageHeight={slideImageHeight}
      {...boxProps}
    />
  )
}

export default RoomsSlider
