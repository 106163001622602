import {
  Box,
  Button,
  ImageListItem,
  ImageListItemBar,
  styled,
  Typography,
} from '@material-ui/core'
import { Add, Check } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import { Room } from '../../types/Venue'
import { venueRoomsDetailsPath } from '../../utils/paths'
import FloorNumber from '../svg/FloorNumber'
import Guests from '../svg/Guests'
import PictureOutlined from '../svg/PictureOutlined'
import TotalSquare from '../svg/TotalSquare'
import { Image } from '../ui/Image'
import { TooltipComponent } from '../ui/Tooltip'

export const StyledSubTitle = styled(Typography)`
  margin-left: 10px;
  color: #9396a3;
`

export const StyledListItem = styled(ImageListItem)`
  margin: 5px;
  width: 288px;
  height: 276px;
  box-shadow: 0 4px 18px rgba(210, 210, 210, 0.4);
`

export const StyledItemBottomBar = styled(ImageListItemBar)(
  ({ theme }) => `
    background-color: ${theme.palette.background.default};
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
`
)

export const RoomPropsContainer = styled(Box)(
  ({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
`
)

export const RoomCard: React.FC<{
  room: Room
  shouldBackToRooms?: boolean
  onSelect?: (room: Room) => void
  isSelected?: boolean
}> = ({ room, shouldBackToRooms = false, onSelect, isSelected }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const goToDetails = () => {
    history.push({
      pathname: generatePath(venueRoomsDetailsPath, {
        id: room.venue_id,
        roomId: room.id,
      }),
      state: { shouldBackToRooms: shouldBackToRooms },
    })
  }

  const getSelectAction = () => {
    if (onSelect) {
      return (
        <Button
          onClick={() => onSelect(room)}
          sx={{
            background: (theme) =>
              isSelected
                ? 'rgba(47, 84, 235, 0.1)'
                : theme.palette.common.black,
            color: (theme) =>
              isSelected
                ? theme.palette.primary.main
                : theme.palette.common.white,
            minHeight: '40px',
            textTransform: 'none',
            '&:hover': {
              background: (theme) =>
                isSelected
                  ? 'rgba(47, 84, 235, 0.1)'
                  : theme.palette.common.black,
              opacity: 0.8,
            },
          }}
          style={{ borderRadius: 0 }}
        >
          <Box display="flex" alignItems="center">
            {isSelected ? <Check /> : <Add />}
            <Typography variant="body2" ml="8px">
              {isSelected
                ? t('events.items.added', 'Added')
                : t('events.items.add', 'Add')}
            </Typography>
          </Box>
        </Button>
      )
    }

    return null
  }

  const getListItemTitle = (room: Room) => {
    return (
      <Box display="flex" justifyContent="space-between" width={1}>
        <TooltipComponent placement="top-start" title={room.name}>
          <Typography
            mr={2}
            variant="h4"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {room.name}
          </Typography>
        </TooltipComponent>
      </Box>
    )
  }
  const getListItemFooter = (room: Room) => {
    return (
      <RoomPropsContainer>
        <Box display="flex" mb={2} mt={2}>
          <FloorNumber />
          <StyledSubTitle variant="body2">{room.floors} floors</StyledSubTitle>
        </Box>
        <Box display="flex" mb={2} mt={2}>
          <TotalSquare />
          <StyledSubTitle variant="body2">{room.space} SqFt</StyledSubTitle>
        </Box>
        <Box display="flex" mt={2}>
          <Guests />
          <StyledSubTitle variant="body2">
            {room.capacity} guest max
          </StyledSubTitle>
        </Box>
      </RoomPropsContainer>
    )
  }

  const roomImage = useMemo(() => {
    const sorted = room.images?.sort((a, b) => a.order - b.order)
    return sorted?.[0]
  }, [room])

  return (
    <StyledListItem key={room.id}>
      <Box display="contents" onClick={goToDetails}>
        {roomImage?.path ? (
          <Box
            height={290}
            sx={{ '&:hover': { cursor: 'pointer', opacity: 0.95 } }}
          >
            <Image image={roomImage} imageSize="medium" alt={room.name} />
          </Box>
        ) : (
          <Box
            height="290px"
            flexDirection="column"
            display="flex"
            style={{ background: '#F7F7F8' }}
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <PictureOutlined />
            <Typography variant="body2" fontSize={12} color="#9396A3">
              No image here
            </Typography>
          </Box>
        )}
      </Box>
      <StyledItemBottomBar
        title={getListItemTitle(room)}
        subtitle={getListItemFooter(room)}
        position="below"
      />
      {getSelectAction()}
    </StyledListItem>
  )
}
