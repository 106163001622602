import { Box, Typography } from '@material-ui/core'
import { useWindowSize } from 'hooks/useWindowSize'
import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import { consentCookie } from 'utils/helpers/cookie'
import tracking from 'utils/tracking'

import styles from './cookieConsent.module.css'

export const CustomCookieConsent: React.FC = () => {
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()

  return (
    <CookieConsent
      location="none"
      enableDeclineButton
      declineButtonStyle={{
        background: '#515750',
        padding: '0.875em 1.5em 1rem',
        color: '#fff',
        marginRight: isMobile ? 0 : '1rem',
        fontFamily: 'Poppins',
        fontSize: '15px',
        borderRadius: '0.25rem',
      }}
      declineButtonText={t('common.cookie.acceptNecessary', 'Decline Tracking')}
      buttonText={t('common.cookie.accept', 'Accept Cookies')}
      cookieName="fs-cc"
      style={{
        bottom: isMobile ? '2.5rem' : '1.25rem',
        right: isMobile ? 0 : '1.25rem',
        left: isMobile ? 0 : 'auto',
        maxWidth: isMobile ? '100%' : 400,
        background: '#fff',
        color: '#09090D',
        borderRadius: '0.25rem',
      }}
      buttonStyle={{
        background: '#515750',
        padding: '0.875em 1.5em 1rem',
        color: '#fff',
        fontFamily: 'Poppins',
        fontSize: '15px',
        borderRadius: '0.25rem',
      }}
      buttonClasses={styles.btn}
      declineButtonClasses={styles.btn}
      buttonWrapperClasses={styles.btn_wrapper}
      onDecline={() => {
        consentCookie.setCookie({ isAccepted: false })
      }}
      onAccept={() => {
        consentCookie.setCookie({ isAccepted: true })
        tracking.init()
      }}
    >
      <Box padding="11px">
        <Typography
          variant="h4"
          fontSize="19px"
          fontFamily="Poppins"
          color="#262926"
          mb="0.1875em"
        >
          {t('common.cookie.title', 'Cookie Settings')}
        </Typography>
        <Typography variant="body1" fontSize="15px">
          {t(
            'common.cookie.text',
            'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts.'
          )}
        </Typography>
      </Box>
    </CookieConsent>
  )
}
