const LogoWhite = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.688721 1.19531C8.05319 1.19531 15.3955 1.19531 22.76 1.19531C22.76 8.55978 22.76 15.9021 22.76 23.2665C15.4177 23.2665 8.05319 23.2665 0.688721 23.2665C0.688721 15.9243 0.688721 8.55978 0.688721 1.19531ZM0.999271 1.50586C0.999271 8.64851 0.999271 15.8133 0.999271 22.956C8.14192 22.956 15.3068 22.956 22.4494 22.956C22.4494 21.5363 22.4494 20.1167 22.4494 18.697C18.5232 18.697 14.6191 18.697 10.715 18.697C10.715 18.5861 10.715 18.4974 10.715 18.3865C14.6413 18.3865 18.5453 18.3865 22.4494 18.3865C22.4494 12.7522 22.4494 7.14013 22.4494 1.50586C17.5471 1.50586 12.6449 1.50586 7.74264 1.50586C7.74264 3.67971 7.74264 5.83138 7.74264 7.98305C7.63173 7.98305 7.543 7.98305 7.43209 7.98305C7.43209 5.8092 7.43209 3.65753 7.43209 1.50586C5.28042 1.50586 3.15094 1.50586 0.999271 1.50586Z"
        fill="white"
        stroke="white"
        strokeWidth="0.665464"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default LogoWhite
