import { Box, Typography, useTheme } from '@material-ui/core'
import React, { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { generatePath, useParams } from 'react-router-dom'
import {
  LsRemoveInquiryDetails,
  LsRemoveInquiryFormValues,
} from 'utils/storage'

import img from '../../../assets/images/search-background-img.jpg'
import imgWebp from '../../../assets/images/search-background-img.webp'
import { Messenger } from '../../../components/Messenger'
import Send from '../../../components/svg/Send'
import { Loader } from '../../../components/ui/Loader'
import { RightSideModal } from '../../../components/ui/Modals/RightSideModal'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { EventDraftStatus } from '../../../constants'
import { useEventById } from '../../../hooks/useEventById'
import { usePartner } from '../../../hooks/usePartner'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useUrlParams } from '../../../hooks/useUrlParams'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { EventVendor } from '../../../types/Event'
import { updateEventInquiries } from '../../../utils/api/marketplace'
import { getModalContent } from '../../../utils/helpers/bookings'
import { getRequestError } from '../../../utils/helpers/validations'
import {
  convertInquiryEventToUserEvent,
  createEventToFormValuesMapper,
  createInquiryMapper,
} from '../../../utils/mappers/inquiries'
import { eventPath, inquiryPath, vendorsPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { InquiryModalControlProvider } from '../../../utils/providers/InquiryModalControlProvider'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'
import { useMessageModalData } from '../../../utils/providers/MessageModalProvider'
import {
  LsSetInquiryDetails,
  LsSetInquiryFormValues,
} from '../../../utils/storage'
import { InquiryBottomModal } from '../../inquiry/components/InquiryBottomModal'
import { NotAvailablePage } from '../../NotAvailablePage'

import { ContinuePlanningAlert } from './components/ ContinuePlanningAlert'
import { EventServices } from './components/EventServices'
import {
  EventDetailsWrapper,
  EventServicesContainer,
  EventServicesWrapper,
  StyledBox,
  StyledConciergeSupportTypography,
} from './Events.styles'

const AbsDetailsWrapper = lazy(() =>
  import('./components/AbsDetailsWrapper').then(({ AbsDetailsWrapper }) => ({
    default: AbsDetailsWrapper,
  }))
)
const DetailsWrapper = lazy(() =>
  import('./components/DetailsWrapper').then(({ DetailsWrapper }) => ({
    default: DetailsWrapper,
  }))
)

export const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { isMobile } = useWindowSize()

  const { authUser } = useAuthData()
  const history = useHistory()
  const { events, updateUserActiveEvent, isLoading, setIsLoading } =
    useStorageEventsData()
  const { urlParams } = useUrlParams()
  const { showMessage } = useMessageModalData()
  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl
  const { event: eventData, isDraftEvent, fetchEvent } = useEventById(id)
  const [isMessagesModalOpen, setMessagesModalOpen] = React.useState(false)
  const eventImage = eventData?.venue?.photo?.path || img
  const eventImageWebp = eventData?.venue?.photo?.path || imgWebp
  const theme = useTheme()
  const { isAbs } = usePartner()
  const { t } = useTranslation()
  const openChat = urlParams.openChat
  const shouldRenderWarning = !!(
    eventData?.status === EventDraftStatus ||
    eventData?.venue?.client_owned ||
    (eventData?.venue?.partner && !eventData?.vendors?.length)
  )

  const handleGoToEdit = (withPush = false) => {
    const inquiryFormValues = createEventToFormValuesMapper(eventData)
    const inquiryDetails = createInquiryMapper(inquiryFormValues, authUser, {
      ...eventData?.venue,
      client_venue: eventData?.client_venue,
    } as any)

    LsSetInquiryFormValues(inquiryFormValues)
    LsSetInquiryDetails(inquiryDetails)

    if (
      isDraftEvent &&
      ((!isAbs && !eventData?.venue) || (isAbs && !eventData?.vendors?.length))
    ) {
      if (!isAbs) modalControl.toggleModal({ name: 'selectVenueType' })
      else history.push(vendorsPath)
    } else {
      if (withPush) {
        history.push(inquiryPath)
      } else {
        toggleModal({
          name: 'inquiryEdit',
          venue: {
            ...eventData?.venue,
            client_venue: eventData?.client_venue,
            aboutInfo: { maxGuests: eventData?.venue?.maxGuests },
          },
          rooms: eventData?.venue?.rooms || [],
        })
      }
    }
  }

  const handleModalClose = () => {
    toggleModal()
    LsRemoveInquiryFormValues()
    LsRemoveInquiryDetails()
  }

  const onToggleMessenger = () => {
    if (openChat && isMessagesModalOpen) {
      history.replace(
        generatePath(eventPath, {
          id: eventData?.id,
        })
      )
    }
    setMessagesModalOpen(!isMessagesModalOpen)
  }

  const updateEventServices = async (removedVendor?: EventVendor) => {
    setIsLoading(true)
    const inquiryFormValues = createEventToFormValuesMapper(eventData)
    const inquiryDetails = createInquiryMapper(inquiryFormValues, authUser, {
      ...eventData?.venue,
      client_venue: eventData?.client_venue,
    } as any)
    const eventVendors = removedVendor
      ? eventData?.vendors?.filter(
          (item: any) => item.id.toString() !== removedVendor.id.toString()
        )
      : eventData?.vendors
    const vendors = eventVendors?.map((vendor: any) => vendor.id) || []

    const inquiryData = { ...inquiryDetails, packages: [], vendors }

    try {
      const data = await updateEventInquiries({
        ...inquiryData,
        id: eventData?.id,
      })
      const event = convertInquiryEventToUserEvent({
        ...data,
        inquiry: data.inquiries_related[0],
      })

      updateUserActiveEvent({ changed: false, ...event })
    } catch (e) {
      showMessage({
        title: getRequestError(e),
        type: 'error',
      })
    }
    setIsLoading(false)
  }

  const updateEventServicesAction = async (removedVendor?: EventVendor) => {
    if (isDraftEvent)
      updateUserActiveEvent({
        vendors: eventData?.vendors?.filter(
          (vendor) => vendor.id !== removedVendor?.id
        ),
      })
    else await updateEventServices(removedVendor)
  }

  const getHint = () => {
    if (isDraftEvent) {
      return (
        <ContinuePlanningAlert
          actionText={t('inquiry.alert.submit', 'Submit inquiry')}
          title={t(
            'inquiry.alert.title',
            'To continue your planning you need to submit your inquiry'
          )}
          subtitle={t(
            'inquiry.alert.subtitle',
            'Please log in to continue making deals and planning your event'
          )}
          handleGoToEdit={() => handleGoToEdit(!!eventData?.start_date)}
        />
      )
    } else if (
      (eventData?.venue?.client_owned || eventData?.venue?.partner) &&
      !eventData?.vendors?.length
    ) {
      return (
        <ContinuePlanningAlert
          title={
            isAbs
              ? t(
                  'partners.abs.bookings.vendors.noVendors',
                  'Please add vendors to continue planning your booking'
                )
              : t(
                  'venue.own.warning',
                  'Please add vendors to continue planning your event'
                )
          }
        />
      )
    } else return
  }

  if (!id && !authUser?.id) {
    return <Loader position="fixed" />
  } else if (id && !events.find((event) => event.id === id)) {
    return <NotAvailablePage type="403" />
  }

  return (
    <InquiryModalControlProvider>
      <LikesDataProvider>
        <Box
          mb={isAbs ? 0 : isMobile ? '-160px' : '-180px'}
          display="flex"
          overflow="hidden"
          flexDirection="column"
        >
          <EventDetailsWrapper width={1} style={{ position: 'relative' }}>
            <StyledBox
              width={1}
              height={isMobile ? '200px' : 1}
              isMobile={isMobile}
              eventImage={eventImage}
              eventImageWebp={eventImageWebp}
            >
              {isMobile && !isDraftEvent && (
                <Box
                  position="absolute"
                  bottom={20}
                  right={24}
                  padding="8px 16px"
                  display="flex"
                  alignItems="center"
                  style={{
                    cursor: 'pointer',
                    borderRadius: '3px',
                    background: '#fff',
                  }}
                  onClick={onToggleMessenger}
                >
                  <StyledConciergeSupportTypography>
                    {t('common.links.support', 'Concierge support')}
                  </StyledConciergeSupportTypography>
                  <Send fill={theme.palette.primary.light} />
                </Box>
              )}
            </StyledBox>
            <Box className="container">
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Suspense fallback={<Loader />}>
                  {isAbs ? (
                    <AbsDetailsWrapper
                      toggleModal={toggleModal}
                      eventData={eventData}
                      handleGoToEdit={() => handleGoToEdit()}
                      handleContinue={() =>
                        handleGoToEdit(!!eventData?.start_date)
                      }
                    />
                  ) : (
                    <DetailsWrapper
                      eventData={eventData}
                      handleGoToEdit={() => handleGoToEdit()}
                      handleContinue={() =>
                        handleGoToEdit(!!eventData?.start_date)
                      }
                    />
                  )}
                </Suspense>
                <EventServicesWrapper>
                  {shouldRenderWarning && (
                    <Box
                      mb="20px"
                      mt={isMobile ? 0 : '20px'}
                      ml={isMobile ? 0 : '20px'}
                    >
                      {getHint()}
                    </Box>
                  )}
                  <EventServicesContainer>
                    <Box
                      p={isMobile ? 0 : 4}
                      pb={4}
                      display="flex"
                      justifyContent="space-between"
                    >
                      {!isAbs && (
                        <Typography className="title">
                          {t('events.services.title', 'Event services')}
                        </Typography>
                      )}
                      {!isMobile && !isDraftEvent && (
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: 'pointer' }}
                          onClick={onToggleMessenger}
                        >
                          <StyledConciergeSupportTypography>
                            {t('common.links.support', 'Concierge support')}
                          </StyledConciergeSupportTypography>
                          <Send fill={theme.palette.primary.light} />
                        </Box>
                      )}
                    </Box>
                    <EventServices
                      fetchEvent={fetchEvent}
                      withWarning={shouldRenderWarning}
                      eventData={eventData}
                      onUpdateEventServicesAction={updateEventServicesAction}
                      isLoading={isLoading}
                      onToggleMessenger={onToggleMessenger}
                    />
                  </EventServicesContainer>
                </EventServicesWrapper>
              </Box>
            </Box>
          </EventDetailsWrapper>
        </Box>
        <InquiryBottomModal />
        <SimpleModal
          maxWidth="inherit"
          open={isOpen}
          onClose={handleModalClose}
        >
          {getModalContent(modalData?.name, modalControl)}
        </SimpleModal>
        <RightSideModal
          open={isMessagesModalOpen}
          onClose={onToggleMessenger}
          withCloseButton={false}
        >
          <Messenger
            data={eventData}
            isSales={true}
            onToggleMessenger={onToggleMessenger}
          />
        </RightSideModal>
      </LikesDataProvider>
    </InquiryModalControlProvider>
  )
}
