import { Box, styled } from '@material-ui/core'

export const StyledSearchContainer = styled(Box)`
  background: #20202c;
  margin: 0;
  color: #ffffff;
  padding: 20px;
  width: 100%;
  min-height: 520px;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  input {
    color: #ffffff;
  }
`
