import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik, getIn } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { emailRegex } from '../../../constants'
import { getUrlPartnerParam } from '../../../constants/partners'
import { usePartner } from '../../../hooks/usePartner'
import { AuthMenuActions, AuthMethods } from '../../../types/Auth'
import { AuthLoginRequest } from '../../../types/dtos/auth'
import { Status } from '../../../types/general'
import { googleSignInPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LsGetLastPath, LsSaveLastPath } from '../../../utils/storage'
import Google from '../../svg/GoogleIcon'
import LinkText from '../../ui/Buttons/LinkText/LinkText'
import { SimpleButton } from '../../ui/Buttons/SimpleButton'
import { TextInput } from '../../ui/FormComponents/TextInput'
import { Loader } from '../../ui/Loader'
import { TooltipComponent } from '../../ui/Tooltip'
import { InputsWrapper } from '../Auth.styles'
import { signInValidationSchema } from '../authValidations'

const LogIn: React.FC<{
  onComplete?: (method: AuthMethods) => void
  onForgot?: () => void
  message?: string
  initialEmail?: string
}> = ({ initialEmail, message, onComplete = () => {}, onForgot }) => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    login,
    resetStatus,
    status,
    isLoading,
    error: authError,
    authModalControl,
  } = useAuthData()
  const [showPassword, setShowPassword] = React.useState(false)
  const [isMagicLink, setIsMagicLink] = useState(false)
  const { getUserActiveEvent } = useStorageEventsData()
  const activeEvent = getUserActiveEvent()
  const { isAbs } = usePartner()

  const googleSignInApiPath = useMemo(() => {
    return isAbs
      ? `${googleSignInPath}?${getUrlPartnerParam()}`
      : googleSignInPath
  }, [isAbs])

  const initialFormData: AuthLoginRequest = {
    remember: false,
    magicLink: false,
    email: initialEmail || '',
    password: '',
  }

  useEffect(() => {
    return resetStatus
  }, [])

  useEffect(() => {
    if (status === Status.SUCCESS) {
      const method = isMagicLink ? AuthMethods.Magic : AuthMethods.Simple

      onComplete(method)
    }
  }, [status])

  const onSubmit = React.useCallback(async (values: AuthLoginRequest) => {
    const params = {} as any
    // if (activeEvent?.active) {
    //   const id = activeEvent.id === 'defaultEvent' ? uuid() : activeEvent.id
    //   params.event_to_submit = id
    //   updateUserActiveEvent({ ...activeEvent, id })
    // }
    params.event_to_submit = activeEvent?.id
    const data = values.magicLink ? { ...values, ...params } : values
    await login(data)
    setIsMagicLink(values.magicLink)
  }, [])

  const googleAuth = () => {
    if (!LsGetLastPath()) {
      LsSaveLastPath(location.pathname + location.search)
    }
    window.open(googleSignInApiPath, '_self')
  }

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="center" mb={4}>
        <Typography variant="h3">{t('auth.logIn.logIn', 'Log In')}</Typography>
      </Box>
      {message && (
        <Typography variant="body2" fontWeight={600}>
          {message}
        </Typography>
      )}
      <Formik
        validationSchema={signInValidationSchema()}
        initialValues={initialFormData}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
        }) => {
          const onChange = (value: string) => {
            setFieldValue('email', value)
          }
          const fieldError = getIn(errors, 'email')
          const showError = getIn(touched, 'email') && Boolean(fieldError)
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Box display="flex" mb={5}>
                <InputsWrapper>
                  <TextInput
                    inputProps={{ pattern: emailRegex }}
                    onBlur={(e) => {
                      handleBlur(e)
                      const { value } = e.currentTarget
                      setFieldValue('email', value.trim())
                    }}
                    autoComplete="username"
                    error={touched.email && !!errors.email}
                    helperText={showError && fieldError}
                    name="email"
                    placeholder={t('auth.email.placeholder', 'alex@gmail.com')}
                    value={values.email}
                    onChange={onChange}
                    label={t('auth.email.label', 'Email')}
                  />
                  {!values.magicLink && (
                    <Box mt={2}>
                      <TextInput
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                event.preventDefault()
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        autoComplete="on"
                        name="password"
                        placeholder={t('auth.password.placeholder', '********')}
                        value={values.password}
                        type={showPassword ? 'text' : 'password'}
                        onChange={(value) => setFieldValue('password', value)}
                        label={t('auth.password.label', 'Password')}
                      />
                    </Box>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          setFieldValue('magicLink', !values.magicLink)
                        }
                        checked={values.magicLink}
                        name="magicLink"
                        color="primary"
                      />
                    }
                    label={
                      <TooltipComponent
                        title={
                          <Typography fontSize={12} textAlign="center">
                            <span style={{ fontSize: 13, fontWeight: 500 }}>
                              {t('auth.logIn.easy', 'Easy way to login')}
                            </span>
                            <br />{' '}
                            {t(
                              'auth.logIn.linkSend',
                              'We’ll send a login link to the email above'
                            )}
                          </Typography>
                        }
                        placement="top"
                      >
                        <Typography color="textPrimary" variant="body2">
                          {t(
                            'auth.logIn.authorizationLink',
                            'Use authorization link'
                          )}
                        </Typography>
                      </TooltipComponent>
                    }
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            setFieldValue('remember', !values.remember)
                          }
                          checked={values.remember}
                          name="magicLink"
                          color="primary"
                        />
                      }
                      label={
                        <Typography color="textPrimary" variant="body2">
                          {t('auth.logIn.remember', 'Remember me')}
                        </Typography>
                      }
                    />
                    <LinkText
                      onClick={onForgot}
                      fontSize={14}
                      display="inline"
                      text={t('auth.password.forgot', 'Forgot password?')}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Typography color="#F52222" variant="body2">
                      {authError}
                    </Typography>
                  </Box>
                </InputsWrapper>
              </Box>
              <SimpleButton
                type="submit"
                disabled={!isValid}
                sx={{ mb: 5, width: '100%' }}
                variant="contained"
              >
                {t('auth.logIn.continue', 'Continue ')}
              </SimpleButton>
            </form>
          )
        }}
      </Formik>

      <Box mb={5} display="flex" justifyContent="center">
        <Typography variant="h4">Or</Typography>
      </Box>
      <SimpleButton
        variant="outlined"
        sx={{ mb: 5, minHeight: 48, borderWidth: 1.6 }}
        onClick={googleAuth}
      >
        <Google mr={2} />
        <Typography variant="body1" fontSize={18} fontWeight={500}>
          {t('auth.logIn.google', 'Continue with Google')}
        </Typography>
      </SimpleButton>
      <Box mt="17px" textAlign="right" fontSize="14px">
        <LinkText
          style={{ display: 'inline-block' }}
          onClick={() => {
            history.push(location.pathname + '?action=signup')
            authModalControl.toggleModal({ step: AuthMenuActions.Signup })
          }}
        >
          {t('auth.logIn.toSignUp', 'New here? Go to Register')}
        </LinkText>
      </Box>
      {isLoading && <Loader position="absolute" />}
    </Box>
  )
}

export default LogIn
