import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { UserRoles } from '../../constants/profile'
import { useAuth } from '../../hooks/useAuth'
import { simpleModalControl, useSimpleModal } from '../../hooks/useSimpleModal'
import { AuthUser } from '../../types/Auth'
import {
  AuthLoginRequest,
  SimpleRegistrationRequest,
} from '../../types/dtos/auth'
import { Status } from '../../types/general'
import { ProfileData } from '../../types/Profile'
import { clearObject } from '../helpers/data'
import { venuesPath } from '../paths'
import { LsGetAuth, LsGetCurrentUser, LsRemoveAuth } from '../storage'

import { useMessageModalData } from './MessageModalProvider'

export interface AuthProviderProps {
  status: Status
  error: string
  resetStatus: () => void
  authUser: ProfileData | null
  login: (data: AuthLoginRequest) => Promise<AuthUser | undefined>
  loginAsUser: (id: string) => Promise<AuthUser | undefined>
  updateUser: (data: Partial<ProfileData>) => void
  magicLinkLogin: (hash: string) => Promise<AuthUser | undefined>
  forgotPasswordRequest: (email: string) => void
  logout: () => Promise<boolean>
  getProfile: (id?: string) => Promise<void>
  isLoading: boolean
  isAuthorized: boolean
  register: (data: SimpleRegistrationRequest) => Promise<AuthUser | undefined>
  registrationData: Record<string, any>
  updateRegisterData: (data: Record<string, any> | null) => void
  role?: UserRoles
  authModalControl: simpleModalControl
}

export const AuthDataContext = React.createContext<AuthProviderProps>({
  status: Status.INACTIVE,
  error: '',
  resetStatus: () => {},
  authUser: null,
  isLoading: false,
  isAuthorized: false,
  login: () => Promise.resolve(undefined),
  loginAsUser: () => Promise.resolve(undefined),
  updateUser: () => {},
  magicLinkLogin: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(false),
  register: () => Promise.resolve(undefined),
  registrationData: {},
  updateRegisterData: () => {},
  forgotPasswordRequest: () => {},
  getProfile: () => Promise.resolve(undefined),
  authModalControl: {
    isOpen: false,
    toggleModal: () => {},
    modalData: {},
    updateModalData: () => {},
  },
})

export const AuthDataProvider: React.FC<{ isNotAuth: boolean }> = ({
  children,
  isNotAuth,
}) => {
  const {
    status,
    authUser,
    login,
    logout,
    register,
    resetStatus,
    error,
    magicLinkLogin,
    isInit,
    forgotPasswordRequest,
    getProfile,
    updateUser,
    loginAsUser,
  } = useAuth()
  const [registrationData, setRegistrationData] = useState({})
  const isLoading = !isInit || status === Status.REQUEST
  const isAuthorized = isInit ? Boolean(authUser) : Boolean(LsGetAuth())
  const role = isInit ? authUser?.role : LsGetCurrentUser()?.role
  const authModalControl = useSimpleModal()
  const history = useHistory()
  const { showMessage, closeMessageDialog } = useMessageModalData()
  const { t } = useTranslation()

  useEffect(() => {
    if (status === Status.SUCCESS) {
      resetStatus()
    }
  }, [status])

  useEffect(() => {
    if (isNotAuth) {
      showMessage({
        title: t('auth.expiredModal.title', 'Your authentication expired'),
        text: t('auth.expiredModal.text', 'Please login'),
        type: 'error',
        style: { width: '500px' },
        buttons: [
          {
            label: t('auth.expiredModal.login', 'Login'),
            action: () => {
              history.push(location.pathname + '?action=login')
              closeMessageDialog()
            },
          },
          {
            label: t(
              'auth.expiredModal.continueAsGuest',
              'Continue as a guest'
            ),
            action: () => {
              logout()
              history.push(venuesPath)
              closeMessageDialog()
            },
          },
        ],
      })
    }
  }, [isNotAuth])

  const updateRegisterData = (data: Record<string, any> | null) => {
    if (!data) {
      setRegistrationData({})
    } else {
      setRegistrationData({ ...registrationData, ...clearObject(data) })
    }
  }

  const context = React.useMemo(
    () => ({
      status,
      authUser,
      login,
      logout,
      updateUser,
      register,
      resetStatus,
      isLoading,
      registrationData,
      updateRegisterData,
      error,
      magicLinkLogin,
      forgotPasswordRequest,
      isAuthorized,
      role,
      loginAsUser,
      getProfile,
      authModalControl,
    }),
    [
      status,
      authModalControl,
      isAuthorized,
      authUser,
      login,
      logout,
      register,
      resetStatus,
      isLoading,
      registrationData,
      getProfile,
      updateRegisterData,
      updateUser,
      error,
      magicLinkLogin,
      forgotPasswordRequest,
      loginAsUser,
    ]
  )

  return (
    <AuthDataContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </AuthDataContext.Provider>
  )
}

export const useAuthData = () => {
  const context = React.useContext(AuthDataContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthDataProvider')
  }
  return context
}
