import {
  isPartner,
  partnerUrl,
  getUrlPartnerParam,
} from '../constants/partners'

import { apiUrl, rootUrl } from './config'

const ApiHost = apiUrl().replace('com/api', 'com') || ''

// const landingSourceParam = partnerParam ? partnerParam.replace('partner', 'landing_source') : `partner=${getPartnerParams(location.search.split('=')[1])}`
// const isFromPartnerSource = location.search.split('=')[1] === landingSourceParam.split('=')[1]
export const googleSignInPath = `${ApiHost}/auth/google`

// App paths
export const homeUrl = partnerUrl() ?? rootUrl()
export const venuesPath = '/a/'
export const venuePath = `/a/venues/:slug`
export const venuePathOld = `/a/venues/:id/details`
export const venueRoomsPath = `/a/venues/:id/rooms`
export const venueRoomsDetailsPath = `/a/venues/:id/rooms/:roomId`
export const marketplacePath = '/a/venues'
export const marketplacePathOld = '/a/marketplace'
export const inquiryPath = '/a/inquiry'
export const vendorsPath = '/a/vendors'
export const vendorsTreePath = '/a/vendors/tree'
export const vendorPathOld = `${vendorsPath}/:id/details`
export const vendorPath = `${vendorsPath}/:slug`
export const vendorsCategoryPath = `${vendorsPath}/category/:category?`
export const absAdvantagePath = `${vendorsPath}/advantage`
export const vendorsPromotionsPath = `${vendorsPath}/promotions`
export const venuesPromotionsPath = `${marketplacePath}/promotions`
export const eventsListPath = `/a/my-events`
export const clientCalendarPath = '/a/my-calendar'
export const eventPath = `/a/my-events/:id/details`
export const termsPathDeprecated = `/a/terms`
export const messagesPath = `/a/messages`
export const dashboardPath = `/a/dashboard`
export const dashboardListingPath = `${dashboardPath}/:listingId`
export const dashboardBookingsPath = `${dashboardListingPath}/bookings`
export const dashboardBookingDetailsPath = `${dashboardListingPath}/bookings/:id/details`
export const dashboardBookingInvoiceListPath = `${dashboardListingPath}/bookings/:id/details/invoices-list`
export const dashboardBookingContractsListPath = `${dashboardListingPath}/bookings/:id/details/contracts-list`
export const dashboardBookingDocumentsPath = `${dashboardListingPath}/bookings/:id/details/documents`
export const dashboardBookingChatPath = `${dashboardListingPath}/bookings/:id/details/chat`
export const dashboardBookingActivityPath = `${dashboardListingPath}/bookings/:id/details/activity`
export const dashboardBookingNotesPath = `${dashboardListingPath}/bookings/:id/details/notes`
export const dashboardBookingVendorsInfoPath = `${dashboardListingPath}/bookings/:id/details/vendors-info`
export const dashboardProfilePath = `${dashboardPath}/profile`
export const dashboardProfilePersonalInfoPath = `${dashboardPath}/profile/personal-info`
export const dashboardProfileSecurityPath = `${dashboardPath}/profile/security`
export const dashboardBusinessDetailsPath = `${dashboardListingPath}/business`
export const dashboardBusinessDescriptionPath = `${dashboardListingPath}/business/description`
export const dashboardBusinessDescriptionDetailsPath = `${dashboardListingPath}/business/details`
export const dashboardBusinessRoomsPath = `${dashboardListingPath}/business/rooms`
export const dashboardBusinessPackagesPath = `${dashboardListingPath}/business/packages`
export const dashboardBusinessPricingPath = `${dashboardListingPath}/business/pricing`
export const dashboardBusinessLineItemsPath = `${dashboardListingPath}/business/line-items`
export const dashboardBusinessContactsPath = `${dashboardListingPath}/business/contacts`
export const dashboardBusinessFilesPath = `${dashboardPath}/business/files`
export const generateInvoicesPath = `${dashboardListingPath}/bookings/:id/invoices`
export const messagesWarningDeactivatePath = `${messagesPath}/warning-deactivate`
export const messagesSuccessDeactivatePath = `${messagesPath}/success-deactivate`
export const messagesSuccessMagicLinkPath = `${messagesPath}/success-magic-link`
export const messagesErrorMagicLinkPath = `${messagesPath}/expired-magic-link`
export const messagesSuccessNotLoggedInquiryPath = `${messagesPath}/success-inquiry-link`
export const onboardingRolePath = '/a/onboarding/role'
export const onboardingVendorPath = `${onboardingRolePath}/vendor`
export const onboardingVenuePath = `${onboardingRolePath}/venue`
export const profilePath = '/a/account/profile'
export const profilePersonalInfoPath = '/a/account/profile/personal-info'
export const profileSecurityPath = '/a/account/profile/security'
export const maintenancePath = '/a/maintenance'
export const mobileMapPath = '/a/map'

export const getMarketplacePath = (
  params?: string | string[][] | Record<string, string> | URLSearchParams
) => {
  return {
    pathname: marketplacePath,
    search: new URLSearchParams(params).toString(),
  }
}

export const getEventListPath = (
  params?: string | string[][] | Record<string, string> | URLSearchParams
) => {
  return {
    pathname: eventsListPath,
    search: new URLSearchParams(params).toString(),
  }
}

export const getEventDetailsPath = (
  pathname: string,
  params?: string | string[][] | Record<string, string> | URLSearchParams
) => {
  return { pathname, search: new URLSearchParams(params).toString() }
}

export const squareOauthUrl = `${apiUrl()}/square/connect`

// API paths
// Common API
export const refreshSessionPath = `${apiUrl()}`.replace(
  'com/api',
  'com/sanctum/csrf-cookie'
)
export const venuesApiPath = `${apiUrl()}/venue`
export const venueCategoriesApiPath = `${apiUrl()}/categories`
export const getVenueApiPath = (id: string) => `${venuesApiPath}/${id}`
export const venuePricingTypesApiPath = `${apiUrl()}/venue-pricing-details`
export const getVenueSlugApiPath = (slug: string) =>
  `${venuesApiPath}/slug/${slug}`
export const getVenueMapApiPath = (id: string) => `${apiUrl()}/mapImage/${id}`
export const getVenueRoomsApiPath = (id: string) =>
  `${venuesApiPath}/${id}/room`
export const getVenueRoomApiPath = (id: string, roomId: string) =>
  `${venuesApiPath}/${id}/room/${roomId}`
export const getEventTypesApiPath = (partner?: string) =>
  !!partner
    ? partner === 'all'
      ? `${apiUrl()}/event-types?type=${partner}`
      : `${apiUrl()}/event-types?partner=${partner}`
    : isPartner()
    ? `${apiUrl()}/event-types?${getUrlPartnerParam()}`
    : `${apiUrl()}/event-types`
export const eventStylesApiPath = () =>
  isPartner()
    ? `${apiUrl()}/event-styles?${getUrlPartnerParam()}`
    : `${apiUrl()}/event-styles`
export const locationsApiPath = `${apiUrl()}/location/cities`
export const lineItemsApiPath = `${apiUrl()}/line-items`
export const lineItemsTypesApiPath = `${apiUrl()}/line-items/types`
export const createEventInquiriesForGuestApiPath = () =>
  isPartner()
    ? `${apiUrl()}/event/storeForGuest?${getUrlPartnerParam()}`
    : `${apiUrl()}/event/storeForGuest`
export const createEventInquiriesForUserApiPath = `${apiUrl()}/event`
export const updateEventInquiriesApiPath = (id: string) =>
  `${apiUrl()}/event/${id}`
export const vendorsApiPath = `${apiUrl()}/vendor`
export const getVendorApiPath = (id: string) => `${vendorsApiPath}/${id}`
export const getVendorSlugApiPath = (slug: string) =>
  `${vendorsApiPath}/slug/${slug}`
export const getLineItemsApiPath = (id: string) => `${lineItemsApiPath}/${id}`
export const vendorTypesApiPath = () =>
  isPartner()
    ? `${apiUrl()}/vendor-types?${getUrlPartnerParam()}`
    : `${apiUrl()}/vendor-types?partner=blace`
export const vendorTypesWithVendorsApiPath = () =>
  isPartner()
    ? `${apiUrl()}/vendor-types-with-vendors?${getUrlPartnerParam()}`
    : `${apiUrl()}/vendor-types-with-vendors?partner=blace`
export const labelsApiPath = `${apiUrl()}/label`
export const imagesApiPath = `${apiUrl()}/image`
export const globalSearchPath = () =>
  isPartner()
    ? `${apiUrl()}/search?${getUrlPartnerParam()}`
    : `${apiUrl()}/search`
export const globalGuestSearchPath = () =>
  isPartner()
    ? `${apiUrl()}/guest/search?${getUrlPartnerParam()}`
    : `${apiUrl()}/guest/search`
export const disconnectGoogleApiPath = `${apiUrl()}/user/google-disconnect`
export const uploadUserFileApiPath = `${apiUrl()}/user/file`
export const getRemoveFileApiPath = (fileId: string) =>
  `${uploadUserFileApiPath}/${fileId}`
export const getDownloadFileApiPath = (id: number) =>
  `${apiUrl()}/user/file/${id}`
export const jotFormsPath = `${apiUrl()}/jotform`
export const guestJotFormsPath = `${apiUrl()}/jotform/storeForGuest`
export const confirmInvoicePath = `${apiUrl()}/invoice/confirm`
export const createInvoicePaymentApiPath = (id: string | number) =>
  `${apiUrl()}/invoice/${id}/pay`
export const createInvoiceDocumentApiPath = (id: string | number) =>
  `${apiUrl()}/invoice/${id}/document`
export const connectTestSquareApiPath = (id: string | number) =>
  `${apiUrl()}/user/${id}/test-square`
export const generatePartnerMagicLinkHash = `${apiUrl()}/generate-partner-magic-link-hash`

// Auth API
export const signInApiPath = () =>
  isPartner()
    ? `${apiUrl()}/login?${getUrlPartnerParam()}`
    : `${apiUrl()}/login`
export const getMagicSignInApiPath = (hash: string) =>
  isPartner()
    ? `${apiUrl()}/magiclink/${hash}?${getUrlPartnerParam()}`
    : `${apiUrl()}/magiclink/${hash}`
export const signOutApiPath = `${apiUrl()}/logout`
export const signUpApiPath = () =>
  isPartner()
    ? `${apiUrl()}/register?${getUrlPartnerParam()}`
    : `${apiUrl()}/register`
export const uniqueEmailCheckApiPath = `${apiUrl()}/user/unique-email`
export const forgotPasswordApiPath = `${apiUrl()}/passwordReset`
export const profileApiPath = `${apiUrl()}/user/profile`

// Onboarding API
export const guestsOnboardingVendorApiPath = () =>
  isPartner()
    ? `${apiUrl()}/onboarding/vendor?${getUrlPartnerParam()}`
    : `${apiUrl()}/onboarding/vendor`
export const guestsOnboardingLandlordApiPath = () =>
  isPartner()
    ? `${apiUrl()}/onboarding/venue?${getUrlPartnerParam()}`
    : `${apiUrl()}/onboarding/venue`
export const usersOnboardingVendorApiPath = () =>
  isPartner()
    ? `${apiUrl()}/vendor?${getUrlPartnerParam()}`
    : `${apiUrl()}/vendor`
export const usersOnboardingLandlordApiPath = () =>
  isPartner()
    ? `${apiUrl()}/venue?${getUrlPartnerParam()}`
    : `${apiUrl()}/venue`
export const reattachOnboardingRoleApiPath = `${apiUrl()}/onboarding/unlink`

// Client events API
export const eventApiPath = `${apiUrl()}/event`
export const clientEventsApiPath = `${apiUrl()}/client/events`
export const getEventApiPath = (id: string) => `${eventApiPath}/${id}`
export const getEventAddVendorApiPath = (id: string) =>
  `${eventApiPath}/${id}/add-vendor`
export const eventDuplicateApiPath = `${apiUrl()}/event/duplicate`

// Business events API
export const businessEventsApiPath = (businessId: string) =>
  `${apiUrl()}/business/${businessId}/events`

// Inquiry API
export const inquiryStatusesApiPath = `${apiUrl()}/event/statuses`
export const inquiryApiPath = `${apiUrl()}/inquiry`
export const inquiryApiPathForGuests = () =>
  isPartner()
    ? `${apiUrl()}/event/storeForGuest?${getUrlPartnerParam()}`
    : `${apiUrl()}/event/storeForGuest`
export const getInquiryApiPath = (id: string) => `${inquiryApiPath}/${id}`
export const getContractDownloadApiPath = (contractId: string) =>
  `${inquiryApiPath}/contract/download/${contractId}`
export const getContractApiPath = (inquiryId: string) =>
  `${apiUrl()}/inquiry/${inquiryId}/contract`
export const getInvoiceApiPath = (inquiryId: string) =>
  `${apiUrl()}/inquiry/${inquiryId}/invoice`
export const getDefaultInvoicesApiPath = (inquiryId: string) =>
  `${apiUrl()}/inquiry/${inquiryId}/default-invoices`
export const getInquirySubmitApiPath = (inquiryId: string | number) =>
  `${apiUrl()}/inquiry/${inquiryId}/submit`
export const syncContractApiPath = (contractId: string) =>
  `${apiUrl()}/inquiry/contract/sync/${contractId}`
export const getSendContractAndInvoicesBundleApiPath = (inquiryId: string) =>
  `${apiUrl()}/inquiry/${inquiryId}/send-bundle`
export const getSendContractApiPath = (inquiryId: string, contractId: number) =>
  `${apiUrl()}/inquiry/${inquiryId}/contract/${contractId}/send`
export const getSendInvoicesBundleApiPath = (
  inquiryId: string,
  invoiceBundleId: number
) => `${apiUrl()}/inquiry/${inquiryId}/invoice-bundle/${invoiceBundleId}/send`

export const vendorPackageApiPath = `${apiUrl()}/package`
export const getVendorPackageApiPath = (id: string) =>
  `${vendorPackageApiPath}/${id}`

export const adminDashboardApiPath = `${ApiHost}/admin`
export const adminUsersListApiPath = `${apiUrl()}/user/list`
export const settingsApiPath = `${apiUrl()}/settings`
export const connectSquareApiPath = `${apiUrl()}/user/connect/square`
export const createPaymentApiPath = `${apiUrl()}/payment`
export const getPaymentCheckoutApiPath = (paymentId: string) =>
  `${createPaymentApiPath}/${paymentId}/session`
export const getAdminLoginAsUserApiPath = (id: string) =>
  `${apiUrl()}/login/${id}`

// Messages API
export const messagesApiPath = `${apiUrl()}/user-message-thread`
export const getMessagesApiPath = (id: string) => `${messagesApiPath}/${id}`
export const salesMessagesApiPath = `${apiUrl()}/sales-message-thread`
export const messagesTemplatesApiPath = `${apiUrl()}/message-templates`
export const getSalesMessagesApiPath = (id: string) =>
  `${salesMessagesApiPath}/${id}`
export const getMessageDownloadFileApiPath = (
  threadId: string,
  fileId: string
) => `${apiUrl()}/user-message-thread/${threadId}/download-file/${fileId}`
export const getSalesMessageDownloadFileApiPath = (
  threadId: string,
  fileId: string
) => `${apiUrl()}/sales-message-thread/${threadId}/download-file/${fileId}`

// Notifications API
export const clientNotificationsApiPath = `${apiUrl()}/client/notifications`
export const businessNotificationsApiPath = (businessId: string) =>
  `${apiUrl()}/business/${businessId}/notifications`
export const notificationsReadAllApiPath = `${apiUrl()}/notification/read-all`
