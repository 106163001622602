import { EXTERNAL_LINKS } from 'constants/externalLinks'

import { Box, Divider, Grid, Link, styled, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import config from 'utils/config'

import BlaceLogoDark from '../../assets/images/blace-dark.png'
import BlaceLogo from '../../assets/images/blace.png'
import { usePartner } from '../../hooks/usePartner'
import { useWindowSize } from '../../hooks/useWindowSize'
import { vendorsPath } from '../../utils/paths'
import Flodesk from '../Flodesk/Flodesk'
import { NavLink } from '../NavLink'
import FacebookIcon from '../svg/FacebookIcon'
import InstagrammIcon from '../svg/InstagrammIcon'
import LinkedInIcon from '../svg/LinkedInIcon'
import TwitterIcon from '../svg/TwitterIcon'
import GradientButton from '../ui/Buttons/GradientButton'

import { FooterWrapper, SocialWrapper } from './Footer.styles'

const StyledGradientButton = styled(GradientButton)`
  &:hover {
    opacity: 0.8;
  }
`

export default function Footer() {
  const onSocialClick = (social: string) => {
    let link = EXTERNAL_LINKS.MEDIA.FACEBOOK

    switch (social) {
      case 'facebook':
        link = EXTERNAL_LINKS.MEDIA.FACEBOOK
        break
      case 'twitter':
        link = EXTERNAL_LINKS.MEDIA.TWITTER
        break
      case 'insta':
        link = EXTERNAL_LINKS.MEDIA.INSTAGRAM
        break
      case 'linkedIn':
        link = EXTERNAL_LINKS.MEDIA.LINKEDIN
        break
      default:
        break
    }

    window.open(link)
  }

  const { t } = useTranslation()

  const { isMobile } = useWindowSize()

  const { isAbs } = usePartner()

  const linksContent = (
    <>
      <Grid
        className="privacy"
        order={isMobile && !isAbs ? 3 : 0}
        display={isAbs && !isMobile ? 'flex' : 'block'}
      >
        {!isMobile && !isAbs && (
          <>
            <b>{t('common.copyright', '© 2022 BLACE')}</b>
            <Typography sx={{ marginRight: 5, marginLeft: 5 }}>
              {t('common.copyright2', 'All Rights Reserved')}
            </Typography>
            <Typography>|</Typography>
          </>
        )}
        {isAbs && (
          <>
            {isMobile && (
              <Box mb="24px">
                <Link
                  sx={{
                    color: (theme) => theme.palette.primary.light,
                    marginRight: 5,
                    marginLeft: isMobile ? 0 : 5,
                    '&:hover': { opacity: 0.8 },
                  }}
                  underline="none"
                  href={EXTERNAL_LINKS.PRIVACY}
                >
                  {t('footer.privacy', 'Privacy')}
                </Link>
                |
                <Link
                  sx={{
                    color: (theme) => theme.palette.primary.light,
                    marginRight: 5,
                    marginLeft: 5,
                    '&:hover': { opacity: 0.8 },
                  }}
                  underline="none"
                  href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS}
                >
                  {t('footer.terms', 'Terms')}
                </Link>
                |
                <Link
                  href={EXTERNAL_LINKS.ABS.FAQ}
                  sx={{
                    color: (theme) => theme.palette.primary.light,
                    marginRight: isMobile ? 0 : 5,
                    marginLeft: 5,
                    '&:hover': { opacity: 0.8 },
                  }}
                  underline="none"
                  target="_blank"
                  variant="body2"
                >
                  {t('footer.faq', 'FAQ')}
                </Link>
              </Box>
            )}
            <Box mb={isMobile ? '24px' : 0}>
              <b>{t('partners.abs.copyright', '© 2022 ABS Partners')}</b>
              <Typography
                sx={{
                  marginRight: isMobile ? 0 : 5,
                  marginLeft: isMobile ? 2 : 5,
                }}
              >
                {t('common.copyright2', 'All Rights Reserved')}
              </Typography>
              {!isMobile && <Typography>|</Typography>}
            </Box>
          </>
        )}
        {!(isAbs && isMobile) && (
          <>
            <Link
              sx={{
                color: (theme) => theme.palette.primary.light,
                marginRight: 5,
                marginLeft: isMobile ? 0 : 5,
                '&:hover': { opacity: 0.8 },
              }}
              underline="none"
              href={EXTERNAL_LINKS.PRIVACY}
            >
              {t('footer.privacy', 'Privacy')}
            </Link>
            |
            <Link
              sx={{
                color: (theme) => theme.palette.primary.light,
                marginRight: 5,
                marginLeft: 5,
                '&:hover': { opacity: 0.8 },
              }}
              underline="none"
              href={EXTERNAL_LINKS.TERMS_AND_CONDITIONS}
            >
              {t('footer.terms', 'Terms')}
            </Link>
          </>
        )}
        {isAbs && !isMobile && (
          <>
            |
            <Link
              href={EXTERNAL_LINKS.ABS.FAQ}
              sx={{
                color: (theme) => theme.palette.primary.light,
                marginRight: isMobile ? 0 : 5,
                marginLeft: 5,
                '&:hover': { opacity: 0.8 },
              }}
              underline="none"
              target="_blank"
              variant="body2"
            >
              {t('footer.faq', 'FAQ')}
            </Link>
          </>
        )}
      </Grid>

      <Grid className="socials" order={isMobile ? 1 : 0}>
        {isAbs ? (
          <Box className="privacy" width="100%">
            <b>{t('common.copyright', '© 2022 BLACE')}</b>
            <Typography sx={{ marginLeft: isMobile ? 2 : 5 }}>
              {t('common.copyright2', 'All Rights Reserved')}
            </Typography>
          </Box>
        ) : (
          <>
            <SocialWrapper onClick={() => onSocialClick('facebook')}>
              <FacebookIcon />
            </SocialWrapper>
            <SocialWrapper onClick={() => onSocialClick('twitter')}>
              <TwitterIcon />
            </SocialWrapper>
            <SocialWrapper onClick={() => onSocialClick('insta')}>
              <InstagrammIcon />
            </SocialWrapper>
            <SocialWrapper onClick={() => onSocialClick('linkedIn')}>
              <LinkedInIcon />
            </SocialWrapper>
          </>
        )}
      </Grid>
    </>
  )

  return (
    <FooterWrapper
      style={{
        textAlign: isAbs && isMobile ? 'center' : 'left',
        paddingTop: isAbs ? (isMobile ? '140px' : '200px') : '50px',
        paddingBottom: isMobile ? (isAbs ? '134px' : '120px') : '110px',
      }}
    >
      <Box className="container" flexDirection="column" flex={1}>
        <Grid
          container
          style={{ marginBottom: isAbs && isMobile ? 69 : 48 }}
          justifyContent={isAbs ? 'space-between' : 'flex-start'}
          flexDirection={isMobile && isAbs ? 'column' : 'row'}
        >
          <Box
            mx={isAbs && isMobile ? 'auto' : 0}
            mb={isAbs && isMobile ? '69px' : 0}
          >
            {isAbs && (
              <Typography
                mb={4}
                sx={{
                  textTransform: 'uppercase',
                  color: (theme) => theme.palette.secondary.dark,
                }}
              >
                Powered By
              </Typography>
            )}
            <Link
              underline="none"
              href="https://www.blace.com/"
              className="logo-wrapper"
              component="a"
              width={122}
              height={22}
            >
              <img
                src={isAbs ? BlaceLogoDark : BlaceLogo}
                width={isAbs ? 118 : 180}
                alt="Blace logo"
              />
            </Link>
          </Box>
          {isAbs && (
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              sx={{ textTransform: 'uppercase' }}
            >
              <NavLink
                to={vendorsPath}
                sx={{
                  marginBottom: isMobile ? '32px' : 0,
                  color: (theme) => theme.palette.secondary.dark,
                  '&:hover': { opacity: 0.8 },
                }}
              >
                <Typography variant="subtitle2">
                  {t('footer.vendors', 'Vendors Listing')}
                </Typography>
              </NavLink>
            </Box>
          )}
        </Grid>
        {!isAbs && (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Grid className="left-block" order={isMobile ? 1 : 0}>
              <Grid className="links-table-grid">
                <Grid className="row">
                  <Grid className="cell">
                    <Typography className="title">
                      {t('common.title', 'Blace')}
                    </Typography>
                    <Link
                      underline="none"
                      color="#BBBBBB"
                      sx={{ '&:hover': { opacity: 0.8 } }}
                      href={EXTERNAL_LINKS.ABOUT_US}
                    >
                      {t('footer.about', 'ABOUT')}
                    </Link>
                    <Link
                      target="_blank"
                      href={EXTERNAL_LINKS.FAQ}
                      sx={{ '&:hover': { opacity: 0.8 } }}
                      underline="none"
                      color="#BBBBBB"
                      variant="body2"
                    >
                      {t('footer.faq', 'FAQ')}
                    </Link>
                    <Link
                      href={EXTERNAL_LINKS.CONTACT_US}
                      sx={{ '&:hover': { opacity: 0.8 } }}
                      underline="none"
                      color="#BBBBBB"
                      variant="body2"
                    >
                      {t('footer.contactUs', 'Contact Us')}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="right-block">
              {config.flodesk.id && <Flodesk formId={config.flodesk.id} />}
            </Grid>
          </Box>
        )}
        <Box display="flex" flexDirection="column">
          {!(isAbs && isMobile) && (
            <Box order={isMobile ? 2 : 0}>
              <Divider
                style={{
                  background: '#9396A3',
                  marginBottom: 32,
                  marginTop: isAbs ? '28px' : 0,
                }}
              />
            </Box>
          )}
          {!isMobile ? (
            <Box display="flex" justifyContent="space-between">
              {linksContent}
            </Box>
          ) : (
            <>{linksContent}</>
          )}
        </Box>
      </Box>
    </FooterWrapper>
  )
}
