import { Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath, useLocation } from 'react-router-dom'

import { BottomFixedActionBlock } from '../../../components/BottomFixedActionBlock'
import { StyledSubTitle } from '../../../components/cards/RoomCard'
import {
  GalleryForEntities,
  GalleryPhotos,
} from '../../../components/GalleryForEntities'
import FloorNumber from '../../../components/svg/FloorNumber'
import Guests from '../../../components/svg/Guests'
import TotalSquare from '../../../components/svg/TotalSquare'
import GradientButton from '../../../components/ui/Buttons/GradientButton/GradientButton'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PageTitle } from '../../../components/ui/PageTitle'
import { UserRoles } from '../../../constants/profile'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVenueById } from '../../../hooks/useVenueById'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { GalleryImage, ImageItem } from '../../../types/general'
import { Room } from '../../../types/Venue'
import { getModalContent } from '../../../utils/helpers/bookings'
import { imagesToGalleryImages } from '../../../utils/helpers/common'
import { formatToCurrency } from '../../../utils/helpers/price'
import { venuePathOld, venueRoomsPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { RoomPricing } from '../Rooms/components/RoomPricing'

export const VenueRoomDetails: React.FC<{ roomData?: Room }> = () => {
  const {
    params: { id, roomId },
  } = useRouteMatch<{ id: string; roomId: string }>()
  const history = useHistory()
  const [showGallery, setShowGallery] = React.useState<boolean>(false)
  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>([])
  const [roomPhotos, setRoomPhotos] = React.useState<GalleryPhotos>()
  const { venue } = useVenueById(id)
  const modalControl = useSimpleModal()
  const { isOpen, toggleModal, modalData } = modalControl
  const { isMobile } = useWindowSize()
  const { t } = useTranslation()
  const { authUser } = useAuthData()
  const location = useLocation()

  const room = useMemo(() => {
    return venue?.rooms?.find((r) => r.id.toString() === roomId)
  }, [venue])

  const openCreateInquiryForm = () => {
    localStorage.removeItem('inquiryFormValues')
    toggleModal({ name: 'inquiryCreate', venue, rooms: room ? [room] : [] })
  }

  React.useEffect(() => {
    if (room?.images?.length) {
      const preparedGalleryImages = imagesToGalleryImages(
        room?.images.map((image: ImageItem) => image.path)
      )
      setGalleryImages(preparedGalleryImages)
      const firstPhoto = room?.images?.[0]
      const secondPhoto = room?.images?.[1]
      const thirdPhoto = room?.images?.[2]

      setRoomPhotos({
        firstPhoto,
        secondPhoto,
        thirdPhoto,
      })
    }
  }, [room?.images])

  return (
    <Box justifyContent="center" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box className="container" order={isMobile ? 1 : 0}>
          <PageTitle title={room?.name} />
        </Box>
        <GalleryForEntities
          entity="rooms"
          showGallery={showGallery}
          setShowGallery={(value) => setShowGallery(value)}
          photos={roomPhotos}
          galleryImages={galleryImages}
        />
      </Box>
      <Box className="container section-block">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box width="100%">
            {room?.details && (
              <Box
                display="flex"
                flexDirection="column"
                width={isMobile ? '100%' : '70%'}
                mr={isMobile ? 0 : 5}
              >
                <Typography variant="h2">
                  {t('room', 'about', 'About the Space')}
                </Typography>
                <Box>
                  {room?.details.split(/\r\n|\r|\n/).map((item, i) => (
                    <Typography variant="body1" className={`about`} key={i}>
                      {item}
                    </Typography>
                  ))}
                </Box>
              </Box>
            )}
            <RoomPricing room={room} />
          </Box>
          <Box
            display="flex"
            width={isMobile ? '100%' : '28%'}
            flexDirection="column"
            mt={isMobile ? '32px' : 0}
          >
            <Typography variant="h2">Features</Typography>
            <Box display="flex" flexDirection="column">
              <Box display="flex" mb={2} mt={2}>
                <FloorNumber />
                <StyledSubTitle variant="body2">
                  {room?.floors} floors
                </StyledSubTitle>
              </Box>
              <Box display="flex" mb={2} mt={2}>
                <TotalSquare />
                <StyledSubTitle variant="body2">
                  {room?.space} SqFt
                </StyledSubTitle>
              </Box>
              <Box display="flex" mt={2}>
                <Guests />
                <StyledSubTitle variant="body2">
                  {room?.capacity} guest max
                </StyledSubTitle>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <BottomFixedActionBlock>
        <Box
          className="container"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height={1}
        >
          {!isMobile && (
            <Box
              display="flex"
              sx={{ '&:hover': { opacity: 0.8 } }}
              alignItems="center"
              onClick={() =>
                // @ts-ignore
                location?.state?.shouldBackToRooms
                  ? history.push(generatePath(venueRoomsPath, { id: id }))
                  : history.push(generatePath(venuePathOld, { id: id }))
              }
            >
              <ArrowBack sx={{ color: '#2F54EB' }} />
              <Typography
                sx={{
                  color: '#2F54EB',
                  marginLeft: '5px',
                  cursor: 'pointer',
                }}
              >
                {/* @ts-ignore */}
                {location?.state?.shouldBackToRooms
                  ? t('rooms.backToRooms', 'Back to Rooms')
                  : t('rooms.backToVenue', 'Back to Venue')}
              </Typography>
            </Box>
          )}
          <Box
            width={isMobile ? '100%' : 'unset'}
            display="flex"
            alignItems="center"
          >
            <Typography
              sx={{
                mr:
                  !authUser || authUser?.role === UserRoles.Client
                    ? isMobile
                      ? '16px'
                      : '40px'
                    : 0,
                width:
                  (!authUser || authUser?.role === UserRoles.Client) && isMobile
                    ? '50%'
                    : 'unset',
              }}
            >
              {room?.display_pricing
                ? `${formatToCurrency(room?.price, false)} / ${
                    room?.short_pricing_details_label
                  }`
                : room?.pricing_details
                ? ''
                : t(
                    'business.pricing.landlord.notDisplay',
                    'Inquire for Pricing'
                  )}
            </Typography>
            {(!authUser || authUser?.role === UserRoles.Client) && (
              <GradientButton
                style={{ width: isMobile ? '50%' : 'unset' }}
                onClick={openCreateInquiryForm}
              >
                Book {room?.name}
              </GradientButton>
            )}
          </Box>
        </Box>
      </BottomFixedActionBlock>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
    </Box>
  )
}
