// @ts-ignore

import {
  Box,
  Button,
  InputAdornment,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { SxProps } from '@material-ui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { usePartner } from '../../hooks/usePartner'
import { useWindowSize } from '../../hooks/useWindowSize'
import { dashboardPath, marketplacePath, vendorsPath } from '../../utils/paths'
import { useAuthData } from '../../utils/providers/AuthProvider'
import { NavLink } from '../NavLink'
import House from '../svg/House'

import { AccountMenu } from './AccountMenu'
import EventPicker from './EventPicker/EventPicker'
import { HeaderBar, MyEventsInput, StyledAppBar } from './Header.styles'
import { Logo } from './Logo'

const Header: React.FC<
  SxProps & { setRenderSearch?: (renderSearch: boolean) => void }
> = ({ setRenderSearch, ...sxProps }) => {
  const history = useHistory()
  const pagePath = history.location.pathname
  const { authUser, isLoading } = useAuthData()
  const { isMobile } = useWindowSize()
  const { isAbs } = usePartner()

  const isBusinessUser = !isLoading && !!authUser?.role

  const { t } = useTranslation()

  const isActive = (currentPath: string) => currentPath === pagePath

  return (
    <HeaderBar sx={{ ...sxProps }}>
      <StyledAppBar>
        <Box className="container">
          <Toolbar
            sx={{
              justifyContent:
                isAbs && isMobile ? 'space-between' : 'flex-start',
            }}
          >
            <Logo />
            {!isMobile && (
              <Box display="flex" flex={1} justifyContent="center">
                <Box
                  display="flex"
                  width={1}
                  maxWidth={500}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <NavLink to={marketplacePath}>
                    <Button
                      className={
                        isActive(marketplacePath)
                          ? 'header-button active'
                          : 'header-button'
                      }
                    >
                      <Typography variant="subtitle2">
                        {t('header.venues', 'Venues')}
                      </Typography>
                    </Button>
                  </NavLink>
                  <NavLink to={vendorsPath}>
                    <Button
                      className={
                        isActive(vendorsPath)
                          ? 'header-button active'
                          : 'header-button'
                      }
                    >
                      <Typography variant="subtitle2">
                        {t('header.vendors', 'Vendors')}
                      </Typography>
                    </Button>
                  </NavLink>
                  <Link
                    component="a"
                    underline="none"
                    target="_blank"
                    href="https://www.blace.com/blog"
                  >
                    <Button className="header-button">
                      <Typography variant="subtitle2">
                        {t('header.blog', 'Blog')}
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            )}
            <Box
              display="flex"
              flex={isMobile && !isAbs ? 1 : '0 0 auto'}
              justifyContent={isMobile ? 'space-between' : 'center'}
              alignItems="center"
              ml={!isMobile ? 6 : 0}
            >
              {isBusinessUser ? (
                isAbs ? (
                  <NavLink to={dashboardPath}>
                    <Button className="header-button">
                      <Typography
                        sx={{ textTransform: 'uppercase', margin: '0 15px' }}
                        variant="body1"
                        fontFamily="Acumin Pro"
                      >
                        {t('partners.abs.header.dashboard', 'Dashboard')}
                      </Typography>
                    </Button>
                  </NavLink>
                ) : (
                  <Box
                    sx={{ m: 1 }}
                    onClick={() => history.push(dashboardPath)}
                  >
                    <MyEventsInput
                      className="my-event-picker"
                      startAdornment={
                        <InputAdornment
                          style={{ marginLeft: 8, width: 24, color: '#ffff' }}
                          position={'start'}
                        >
                          <House />
                        </InputAdornment>
                      }
                      endAdornment={<Box style={{ width: 24 }} />}
                      placeholder={t('header.dashboard', 'Go to dashboard')}
                      readOnly
                    />
                  </Box>
                )
              ) : (
                <EventPicker />
              )}
              <AccountMenu setRenderSearch={setRenderSearch} />
            </Box>
          </Toolbar>
        </Box>
      </StyledAppBar>
    </HeaderBar>
  )
}

export default Header
