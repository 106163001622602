// @ts-ignore
import { ThemeProvider } from '@emotion/react'
// @ts-ignore
import markerSDK from '@marker.io/browser'
import { styled } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import 'fix-date'
import * as React from 'react'
import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import i18n from 'utils/i18n'
import { excludeProps } from 'utils/styles/styled'

import './App.css'
import { AuthModals } from './components/Auth/AuthModals'
import checkRequests from './components/CheckRequests'
import { ToastNotificationContainer } from './components/Notifications/ToastNotificationContainer'
import Router from './components/Router'
import { CustomCookieConsent } from './components/ui/Modals/CookieConsent'
import { UserRoles } from './constants/profile'
import { useEventListener } from './hooks/useEventListener'
import { usePartner } from './hooks/usePartner'
import { createBlaceTheme } from './theme/createBlaceTheme'
import BugsnagBoundary from './utils/bugsnag'
import { getAppleTouchIcon } from './utils/helpers/appleTouchIcon'
import { isProductionEnv } from './utils/helpers/envr'
import { getFavicon } from './utils/helpers/favicon'
import { setTheme } from './utils/helpers/theme'
import { dashboardPath, mobileMapPath } from './utils/paths'
import { AuthDataProvider, useAuthData } from './utils/providers/AuthProvider'
import CustomErrorBoundary from './utils/providers/CustomErrorBoundary'
import { StorageEventsDataProvider } from './utils/providers/EventsProvider'
import { MessageModalProvider } from './utils/providers/MessageModalProvider'
import tracking from './utils/tracking'
import VQueryClientProvider from './utils/VQueryClientProvider'

tracking.init()

type PageWrapperProps = {
  is_dashboard: boolean
  isMobileMap: boolean
}

const PageWrapper = styled('div', {
  shouldForwardProp: excludeProps<PageWrapperProps>([
    'is_dashboard',
    'isMobileMap',
  ]),
})<PageWrapperProps>(
  (props) => `
    background: white;
    width: 100%;
    height: ${props.isMobileMap ? '100%' : 'unset'};
    min-width: ${props.is_dashboard ? 'fit-content' : 'unset'}
`
)

const App: React.FC<{ isNotAuth: boolean }> = ({ isNotAuth }) => {
  const { isPartner } = usePartner()
  const { authUser } = useAuthData()
  const theme = isPartner ? setTheme() : createBlaceTheme()
  const history = useHistory()
  const location = useLocation()

  let markerIOWidget: any = null

  useEffect(() => {
    if (!isPartner) return

    const favicon = document.querySelector(
      'link[rel="icon"]'
    ) as HTMLAnchorElement | null
    if (favicon) {
      favicon.href = getFavicon()
    }

    const appleTouchIcon = document.querySelector(
      'link[rel="apple-touch-icon"]'
    ) as HTMLAnchorElement | null
    if (appleTouchIcon) {
      appleTouchIcon.href = getAppleTouchIcon()
    }

    const metaTags = document.querySelectorAll('meta')
    const lastMetaTag = metaTags[metaTags.length - 1]

    const noindexMetaTag = document.createElement('meta') as HTMLMetaElement
    noindexMetaTag.setAttribute('name', 'robots')
    noindexMetaTag.content = 'noindex,nofollow'

    if (lastMetaTag) lastMetaTag.after(noindexMetaTag)
  }, [isPartner])

  // // @ts-ignore
  const isLandlord = UserRoles.isLandlord(
    JSON.parse(localStorage.getItem('currentUser') || '{}').role
  )
  const isExclusiveVenue = authUser?.venues?.[0]?.is_exclusive

  const handler = (event: any) => {
    const iframeElement = document.getElementById('feedback-button')
    if (event.keyCode == 81 && event.altKey && iframeElement) {
      iframeElement.style.visibility = 'visible'
    }
  }

  useEventListener('keydown', handler)

  useEffect(() => {
    if (!markerIOWidget) {
      markerIOWidget = markerSDK.loadWidget({
        destination: '61688bd55895792435eba9a6',
      })
      setTimeout(() => {
        const iframeElement = document.getElementById('feedback-button')
        if (
          (isProductionEnv() && isLandlord && isExclusiveVenue) ||
          location.search === '?marker'
        ) {
          if (iframeElement) {
            iframeElement.style.visibility = 'visible'
          }
        } else {
          if (iframeElement) {
            iframeElement.style.visibility = 'hidden'
          }
        }
      }, 3000)
    }
  }, [])

  const isDashboard = history.location.pathname.includes(dashboardPath)
  const isMobileMap = history.location.pathname.includes(mobileMapPath)

  return (
    <BugsnagBoundary>
      <CustomErrorBoundary>
        <VQueryClientProvider>
          <I18nextProvider i18n={i18n}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <MessageModalProvider>
                  <AuthDataProvider isNotAuth={isNotAuth}>
                    <StorageEventsDataProvider>
                      <PageWrapper
                        is_dashboard={Boolean(isDashboard)}
                        isMobileMap={Boolean(isMobileMap)}
                      >
                        <Router />
                        <AuthModals />
                        <CustomCookieConsent />
                      </PageWrapper>
                      <ToastNotificationContainer />
                    </StorageEventsDataProvider>
                  </AuthDataProvider>
                </MessageModalProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </I18nextProvider>
        </VQueryClientProvider>
      </CustomErrorBoundary>
    </BugsnagBoundary>
  )
}

export default checkRequests(App)
