import { Box, styled, Typography } from '@material-ui/core'
import { ArrowBack, StarRounded } from '@material-ui/icons'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { BottomFixedActionBlock } from '../../../components/BottomFixedActionBlock'
import { BusinessContactInfo } from '../../../components/BusinessContactInfo'
import { PromotionDescriptionBlock } from '../../../components/cards/PromotionItem/PromotionDescriptionBlock'
import {
  GalleryForEntities,
  GalleryPhotos,
} from '../../../components/GalleryForEntities'
import { Messenger } from '../../../components/Messenger'
import GradientButton from '../../../components/ui/Buttons/GradientButton'
import { RightSideModal } from '../../../components/ui/Modals/RightSideModal'
import { SimpleModal } from '../../../components/ui/Modals/SimpleModal'
import { PageTitle } from '../../../components/ui/PageTitle'
import { FullLineTabs } from '../../../components/ui/Tabs/FullLineTabs'
import { EventDraftStatus, getVenueTabs } from '../../../constants'
import { InquiryExclusivityTypes } from '../../../constants/inquiry'
import { UserRoles } from '../../../constants/profile'
import { useSimpleModal } from '../../../hooks/useSimpleModal'
import { useVenueById } from '../../../hooks/useVenueById'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { GalleryImage, ImageItem } from '../../../types/general'
import { VenueDetails } from '../../../types/Venue'
import { getModalContent } from '../../../utils/helpers/bookings'
import { imagesToGalleryImages } from '../../../utils/helpers/common'
import { formatToCurrency } from '../../../utils/helpers/price'
import { vendorsMapper } from '../../../utils/mappers/vendors'
import { marketplacePath, venueRoomsPath } from '../../../utils/paths'
import { useAuthData } from '../../../utils/providers/AuthProvider'
import { useStorageEventsData } from '../../../utils/providers/EventsProvider'
import { LikesDataProvider } from '../../../utils/providers/LikesProvider'
import { LsSetDefaultEvents } from '../../../utils/storage'
import tracking from '../../../utils/tracking'
import { InformationModalContent } from '../../events/components/InformationModalContent'
import { IconContainer } from '../../events/Event/Events.styles'
import VenuesSlider from '../components/VenuesSlider/VenuesSlider'

import { AboutTheSpace } from './components/AboutTheSpace'
import { ExclusiveVendors } from './components/ExclusiveVendors'
import { ExclusiveVendorsText } from './components/ExclusiveVendorsText'
import { RoomsList } from './components/RoomsList'
import { StaticMap } from './components/StaticMap'
import { VenueAbout } from './components/VenueAbout'
import { VenueLabels } from './components/VenueLabels'
import { VenuePricing } from './components/VenuePricing'
import { VenueDetailsWrapper } from './Venue.styles'

const StyledBox = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
  cursor: pointer;
`
)

export const Venue: React.FC<{
  venueData?: VenueDetails
  venueId?: string
  showBottomBar?: boolean
}> = ({ venueData, venueId, showBottomBar = true }) => {
  const { t } = useTranslation()

  const { isMobile } = useWindowSize()

  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const history = useHistory()
  const modalControl = useSimpleModal()
  const { authUser } = useAuthData()
  const { isOpen, toggleModal, modalData } = modalControl
  // @ts-ignore
  const { venue } =
    venueId || id
      ? useVenueById(venueId || id, {
          with_exclusive: true,
        })
      : venueData
      ? { venue: venueData }
      : ({} as VenueDetails)
  const { createNewEvent, getUserActiveEvent } = useStorageEventsData()
  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>([])
  const [showGallery, setShowGallery] = React.useState<boolean>(false)
  const [scope, setScope] = React.useState<number | null>(null)
  const [isMessagesModalOpen, setMessagesModalOpen] =
    React.useState<boolean>(false)
  const [venuePhotos, setVenuePhotos] = React.useState<GalleryPhotos>()
  const [exclusiveInfo, setExclusiveInfo] = React.useState<{
    open: boolean
    title: string
    subtitle: string
  }>({
    open: false,
    title: '',
    subtitle: '',
  })
  const [choosenTab, setChoosenTab] = React.useState<boolean>(true)
  const vendors = venue?.exclusiveVendors?.map(vendorsMapper)
  const vendorsExclusive = venue?.exclusiveVendors
    ?.filter(
      (item: any) => item.pivot?.type === InquiryExclusivityTypes.STANDARD
    )
    .map(vendorsMapper)
  const vendorsPreferred = venue?.exclusiveVendors
    ?.filter(
      (item: any) => item.pivot?.type === InquiryExclusivityTypes.PREFERRED
    )
    .map(vendorsMapper)
  const venueTabs = getVenueTabs(!!vendors?.length, !!venue?.labels?.length)

  const eventData = getUserActiveEvent()

  useEffect(() => {
    if (eventData) return
    LsSetDefaultEvents()
  }, [eventData])

  const onToggleMessenger = () => {
    setMessagesModalOpen(!isMessagesModalOpen)
  }

  React.useEffect(() => {
    if (venue?.images?.length) {
      const preparedGalleryImages = imagesToGalleryImages(
        venue.images.map((image: ImageItem) => image.path)
      )
      setGalleryImages(preparedGalleryImages)
      const firstPhoto = venue?.images?.[0]
      const secondPhoto = venue?.images?.[1]
      const thirdPhoto = venue?.images?.[2]

      setVenuePhotos({
        firstPhoto,
        secondPhoto,
        thirdPhoto,
      })
    }
  }, [venue?.images])

  React.useEffect(() => {
    if (venue?.aboutInfo?.maxGuests) {
      if (venue?.aboutInfo?.maxGuests === 0) {
        setScope(Math.ceil(1 / 100) * 100)
      } else setScope(Math.ceil(venue?.aboutInfo?.maxGuests / 100) * 100)
    }
  }, [venue?.aboutInfo?.maxGuests])

  const openCreateInquiryForm = () => {
    const activeEvent = getUserActiveEvent()
    if (activeEvent?.venue?.id) {
      createNewEvent()
    }
    if (venue?.rooms?.length) {
      const venueId = venue?.id ? venue?.id.toString() : id
      history.push(
        generatePath(venueRoomsPath, {
          id: venueId,
        })
      )
      return
    }
    if (venue) tracking.pickedVenue(venue)
    toggleModal({ name: 'inquiryCreate', venue })
  }

  return (
    <LikesDataProvider>
      <VenueDetailsWrapper>
        <Box display="flex" flexDirection="column">
          <Box className="container" order={isMobile ? 1 : 0}>
            <PageTitle title={venue?.name} />
          </Box>
          <GalleryForEntities
            entity="venues"
            showGallery={showGallery}
            setShowGallery={(value) => setShowGallery(value)}
            photos={venuePhotos}
            galleryImages={galleryImages}
            isPromotion={!!venue?.hasPromotion}
          />
        </Box>
        <Box className="container" mt={!isMobile ? 4 : 0}>
          {!isMobile && (
            <FullLineTabs
              tabs={venueTabs}
              onChooseTab={() => setChoosenTab(true)}
            />
          )}
          {!!venue?.hasPromotion && (
            <PromotionDescriptionBlock
              promotionDescription={venue?.promotionDescription}
            />
          )}
          {vendorsExclusive && !!vendorsExclusive.length && (
            <ExclusiveVendorsText
              type={InquiryExclusivityTypes.STANDARD}
              title={t('vendor.exclusives.name', 'Exclusive vendors')}
              subtitle={t(
                'vendor.exclusives.description',
                'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
              )}
              textAttached={t(
                'vendor.exclusives.attached',
                'If you would like to use another vendor, please'
              )}
              textAction={t(
                'vendor.exclusives.action',
                'to contact BLACE about a buy out fee.'
              )}
              openExclusiveInfo={(open, title, subtitle) =>
                setExclusiveInfo({
                  open,
                  title,
                  subtitle,
                })
              }
            />
          )}
          {venue?.inhouseVendorTypes && !!venue?.inhouseVendorTypes?.length && (
            <ExclusiveVendorsText
              type={InquiryExclusivityTypes.IN_HOUSE}
              title={t('vendor.inhouses.name', 'In-house vendors')}
              subtitle={t(
                'vendor.inhouses.description',
                'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
              )}
              textAttached={t(
                'vendor.inhouses.attached',
                'If you would like to use another vendor, please'
              )}
              textAction={t(
                'vendor.inhouses.action',
                'to contact BLACE about a buy out fee.'
              )}
              openExclusiveInfo={(open, title, subtitle) =>
                setExclusiveInfo({
                  open,
                  title,
                  subtitle,
                })
              }
            />
          )}
          {vendorsPreferred && !!vendorsPreferred.length && (
            <ExclusiveVendorsText
              type={InquiryExclusivityTypes.PREFERRED}
              title={t('vendor.preferreds.name', 'Preferred vendors')}
              subtitle={t(
                'vendor.preferreds.description',
                'The vendors who are directly connected with this event. They are obliged to participate in the event. But if you want to replace them with others or refuse their services, you have to pay the Buy out fee'
              )}
              textAttached={t(
                'vendor.preferreds.attached',
                'If you would like to use another vendor, please'
              )}
              textAction={t(
                'vendor.preferreds.action',
                'to contact BLACE about a buy out fee.'
              )}
              openExclusiveInfo={(open, title, subtitle) =>
                setExclusiveInfo({
                  open,
                  title,
                  subtitle,
                })
              }
            />
          )}
          <Box className="content">
            <Box className="details-info">
              <VenueAbout venue={venue} />
              {venue?.labels?.length ? (
                <VenueLabels labels={venue?.labels} />
              ) : null}
              <AboutTheSpace venue={venue} />
              <VenuePricing venue={venue} />
            </Box>
            <Box className="venue-inquiry">
              {(venue?.contact.name ||
                venue?.contact.address.description ||
                venue?.contact.email ||
                venue?.contact.phone ||
                venue?.contact.website) && (
                <BusinessContactInfo business={venue} />
              )}
              {!!venue?.id && <StaticMap venueId={venue?.id.toString()} />}
            </Box>
          </Box>
        </Box>
        <Box className={!isMobile ? 'container' : ''}>
          {venue?.rooms && !!venue.rooms?.length && (
            <Box width={1} id="rooms">
              <RoomsList rooms={venue.rooms} />
            </Box>
          )}
          {((vendors && vendors.length > 0) ||
            (venue?.inhouseVendorTypes &&
              !!venue?.inhouseVendorTypes.length)) && (
            <ExclusiveVendors
              vendorsExclusive={vendorsExclusive}
              inhouseVendorTypes={venue?.inhouseVendorTypes}
              vendorsPreferred={vendorsPreferred}
              openExclusiveInfo={(open, title, subtitle) =>
                setExclusiveInfo({
                  open,
                  title,
                  subtitle,
                })
              }
            />
          )}
          {venue?.aboutInfo?.maxGuests !== null && (
            <VenuesSlider
              nextNavButtonClass="similar_next_button"
              prevNavButtonClass="similar_prev_button"
              venueId={venue?.id}
              params={{ similar: true, scope: scope, city_id: venue?.city_id }}
              slideWidth={288}
              slideImageHeight={380}
              slidesPerView={!isMobile ? 4 : undefined}
              withoutEndFrame={!!isMobile}
              sliderTitle={'Similar venues'}
            />
          )}
        </Box>
      </VenueDetailsWrapper>
      <SimpleModal maxWidth="inherit" open={isOpen} onClose={toggleModal}>
        {getModalContent(modalData?.name, modalControl)}
      </SimpleModal>
      {showBottomBar && (
        <BottomFixedActionBlock choosenTab={choosenTab}>
          <Box
            height="100%"
            className="container"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!isMobile && (
              <StyledBox
                sx={{ '&:hover': { opacity: 0.8 } }}
                display="flex"
                alignItems="center"
                onClick={() => history.push(marketplacePath)}
              >
                <ArrowBack />
                <Typography sx={{ marginLeft: '5px' }}>
                  {t('venue.backToAll', 'Back to Venues')}
                </Typography>
              </StyledBox>
            )}
            <Box
              width={isMobile ? '100%' : 'unset'}
              display="flex"
              alignItems="center"
            >
              <Typography
                sx={{
                  mr:
                    !authUser || authUser?.role === UserRoles.Client
                      ? isMobile
                        ? '16px'
                        : '40px'
                      : 0,
                  width:
                    (!authUser || authUser?.role === UserRoles.Client) &&
                    isMobile
                      ? '50%'
                      : 'unset',
                }}
              >
                {venue?.displayPricing
                  ? `${formatToCurrency(venue?.pricing, false)} / ${
                      venue?.shortPricingTextLabel
                    }`
                  : venue?.paymentStructure?.pricingDetails
                  ? ''
                  : t(
                      'business.pricing.landlord.notDisplay',
                      'Inquire for Pricing'
                    )}
              </Typography>
              {(!authUser || authUser?.role === UserRoles.Client) && (
                <GradientButton
                  onClick={openCreateInquiryForm}
                  style={{ width: isMobile ? '50%' : 'unset' }}
                >
                  {t('events.createInquiry', 'Create Inquiry')}
                </GradientButton>
              )}
            </Box>
          </Box>
        </BottomFixedActionBlock>
      )}
      <SimpleModal
        open={exclusiveInfo.open}
        onClose={() =>
          setExclusiveInfo((prevInfo) => ({ ...prevInfo, open: false }))
        }
      >
        <InformationModalContent
          isEventDraft={eventData?.status === EventDraftStatus}
          description={exclusiveInfo.subtitle}
          icon={
            <IconContainer mb={4}>
              <StarRounded style={{ fontSize: 28, color: '#FFD25A' }} />
            </IconContainer>
          }
          title={exclusiveInfo.title}
          onToggleMessenger={onToggleMessenger}
          onClose={() =>
            setExclusiveInfo((prevInfo) => ({ ...prevInfo, open: false }))
          }
        />
      </SimpleModal>
      <RightSideModal
        open={isMessagesModalOpen}
        onClose={onToggleMessenger}
        withCloseButton={false}
      >
        <Messenger
          data={eventData}
          isSales={true}
          onToggleMessenger={onToggleMessenger}
        />
      </RightSideModal>
    </LikesDataProvider>
  )
}
