import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import * as React from 'react'
import { Fragment } from 'react'

const { REACT_APP_BUGSNAG_KEY: key } = process.env

let BugsnagBoundary: BugsnagErrorBoundary
if (key) {
  Bugsnag.start({
    apiKey: key!,
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: process.env.REACT_APP_ENV,
    // releaseStage: process.env.NODE_ENV,
  })
  // @ts-ignore
  BugsnagBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)
} else {
  BugsnagBoundary = Fragment
}

export default BugsnagBoundary
