import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { dayInMilliseconds, weekInMilliseconds } from '../constants'
import { UserRoles } from '../constants/profile'
import { AuthUser } from '../types/Auth'
import {
  AuthLoginRequest,
  AuthLoginResponse,
  SimpleRegistrationRequest,
} from '../types/dtos/auth'
import { Status } from '../types/general'
import { ProfileData } from '../types/Profile'
import { setAuthorizationToken } from '../utils/api/api'
import {
  forgotPassword,
  magicSignIn,
  signIn,
  signInAsUser,
  signOut,
  signUp,
} from '../utils/api/auth'
import { getUserProfile } from '../utils/api/profile'
import { formatAuthUser } from '../utils/helpers/auth'
import { getRequestError } from '../utils/helpers/validations'
import {
  LsClearUserEvents,
  LsGetAuth,
  LsGetCurrentUser,
  LsRemoveAuth,
  LsSetAuth,
  LsSetCurrentUser,
} from '../utils/storage'

import { useUrlParams } from './useUrlParams'

interface AuthHookData {
  status: Status
  error: string
  isInit: boolean
  resetStatus: () => void
  authUser: ProfileData | null
  login: (data: AuthLoginRequest) => Promise<AuthUser>
  loginAsUser: (id: string) => Promise<AuthUser>
  updateUser: (data: Partial<AuthUser>) => void
  magicLinkLogin: (hash: string) => Promise<AuthUser>
  logout: () => Promise<boolean>
  getProfile: () => Promise<void>
  forgotPasswordRequest: (email: string) => void
  register: (data: SimpleRegistrationRequest) => Promise<AuthUser>
}

export function useAuth(): AuthHookData {
  const { urlParams } = useUrlParams()
  const [isInit, setInit] = React.useState(false)
  const [status, setStatus] = React.useState<Status>(Status.INACTIVE)
  const [error, setError] = React.useState('')
  const [authUser, setAuthUser] = React.useState<ProfileData | null>(null)
  const source = React.useMemo(() => axios.CancelToken.source(), [])
  const token = LsGetAuth()

  React.useEffect(() => {
    if (urlParams.hash) {
      magicLinkLogin(urlParams.hash, urlParams.withReset)
    } else {
      getProfile()
    }
  }, [])

  React.useEffect(() => {
    if (status !== Status.ERROR) {
      setError('')
    }
  }, [status])

  const resetStatus = () => {
    setStatus(Status.INACTIVE)
  }

  const updateUser = (data: Partial<ProfileData>) => {
    const existsUserData = authUser || ({} as ProfileData)
    const updatedUser = { ...existsUserData, ...data } as ProfileData

    if (!updatedUser.role) {
      updatedUser.role = UserRoles.Client
    }

    // Clear old user events
    if (!!LsGetCurrentUser().id) {
      LsClearUserEvents()
    }

    setAuthUser(updatedUser)
    LsSetCurrentUser(updatedUser)
  }

  const defaultLogin = (
    request: Promise<AuthLoginResponse>,
    isMagic?: boolean
  ) => {
    return request
      .then(({ data }: { data: AuthUser }) => {
        if (data?.token) {
          setAuthorizationToken(data.token)
          LsSetAuth(data.token)
          Bugsnag.addMetadata('user', {
            id: data.id,
            name: data.name,
            email: data.email,
            token: data.token,
          })
          updateUser(formatAuthUser(data))
        }
        setStatus(Status.SUCCESS)

        return data
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setError(getRequestError(e))
          setStatus(Status.ERROR)
        }
        return {} as AuthUser
      })
      .finally(() => {
        if (!isInit) {
          setInit(true)
        }
      })
  }

  const login = (data: AuthLoginRequest) => {
    setStatus(Status.REQUEST)
    return defaultLogin(signIn(data, source.token)).then((res) => {
      if (res?.token) {
        // TODO get expiry from back
        const expiry = data.remember ? weekInMilliseconds : dayInMilliseconds
        LsSetAuth(res.token, expiry)
      }
      return res
    })
  }

  const loginAsUser = (userId: string) => {
    setStatus(Status.REQUEST)

    return defaultLogin(signInAsUser(userId))
  }

  const magicLinkLogin = (hash: string, withReset = false) => {
    setStatus(Status.REQUEST)
    if (withReset) {
      localStorage.removeItem('inquiryFormValues')
      localStorage.removeItem('inquiryDetails')
    }
    return defaultLogin(magicSignIn(hash, source.token), true)
  }

  const getProfile = async (id?: string) => {
    if (token) {
      // getUser or smth like that
      setAuthorizationToken(token)
      setStatus(Status.REQUEST)
      return getUserProfile()
        .then(({ data }) => {
          if (data) {
            id
              ? setAuthUser(formatAuthUser(data, id))
              : setAuthUser(formatAuthUser(data))
          } else {
          }
        })
        .catch(() => {
          setAuthorizationToken('')
          LsRemoveAuth()
          Bugsnag.clearMetadata('user')
        })
        .finally(() => {
          setInit(true)
          setStatus(Status.INACTIVE)
        })
    } else {
      setInit(true)
      return undefined
    }
  }

  const logout = () => {
    setStatus(Status.REQUEST)

    return signOut()
      .then(() => {
        setAuthUser(null)
        setAuthorizationToken('')
        setStatus(Status.SUCCESS)
        LsClearUserEvents()
        localStorage.removeItem('inquiryFormValues')
        localStorage.removeItem('inquiryDetails')
        localStorage.removeItem('newEventId')
        localStorage.clear()
        Bugsnag.clearMetadata('user')

        return true
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setStatus(Status.ERROR)
        }
        setAuthorizationToken('')
        LsClearUserEvents()
        setAuthUser(null)
        window.location.reload()

        return false
      })
  }

  const register = (data: SimpleRegistrationRequest) => {
    setStatus(Status.REQUEST)

    return defaultLogin(signUp(data))
  }

  const forgotPasswordRequest = (email: string) => {
    setStatus(Status.REQUEST)

    forgotPassword(email)
      .then(() => {
        setStatus(Status.SUCCESS)
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          setError(getRequestError(e))
          setStatus(Status.ERROR)
        }
      })
  }

  return {
    status,
    resetStatus,
    getProfile,
    authUser,
    login,
    updateUser,
    logout,
    register,
    error,
    magicLinkLogin,
    forgotPasswordRequest,
    isInit,
    loginAsUser,
  }
}
