const SearchMobile = ({ fill = '#D7D7D7' }) => {
  return (
    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 2c-4.411 0-8 3.589-8 8s3.589 8 8 8a7.952 7.952 0 0 0 4.897-1.688l6.189 6.033L23 20.931l-6.189-6.033A7.952 7.952 0 0 0 18.5 10c0-4.411-3.589-8-8-8Z"
        fill={fill}
      />
      <path
        fill={fill}
        d="m15.395 16.335 1.454-1.466 1.42 1.408-1.454 1.466z"
      />
    </svg>
  )
}

export default SearchMobile
